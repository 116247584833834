import { GeoAPI, PortalAPI } from '@platform/api';
import { ProspectSearchRetrieveResponse } from '@platform/api/dist/portal-api/prospect-api.types';
import { ProspectFilter } from '@platform/helpers';
import { Feature, Point } from 'geojson';

export interface CampaignProspects {
  totalRecords: number;
  ids: string[];
}

export type LoadedProspect = PortalAPI.ProspectAPI.ProspectResponse_v3 & {
  isLoading: boolean;
  profundPropertyDataLoading?: boolean;
};

export type ProspectSummary = {
  count: number;
  isLoading?: boolean;
}

export interface ProspectsState {
  prospects: { [id: string]: LoadedProspect };
  prospectsByCampaign: { [campaignId: string]: CampaignProspects };
  activity: { [externalId: string]: { data: PortalAPI.ProspectAPI.ActivityData[]; isLoading: boolean } };
  searchProspects: { [search: string]: { data: PortalAPI.ProspectAPI.ProspectSearchSummaryResponse}};
  filters: ProspectFilter;
  summary: ProspectSummary;
  searchSummary: { [suggestionId: string]: { data: ProspectSearchRetrieveResponse | null; isLoading: boolean } };
  selectedProspect: { id: string; data: Feature<Point, GeoAPI.ProspectGeoJsonProperties> } | null;
}

export const initialProspectsState: ProspectsState = {
  prospects: {},
  prospectsByCampaign: {},
  activity: {},
  searchProspects: {},
  filters: {
    assignedToUserIds: [],
    tagIds: [],
    leadStatusIds: [],
    dates: null,
  },
  summary: {count: 0, isLoading: false},
  searchSummary: {},
  selectedProspect: null
};
