import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/24/outline';

export interface DropdownMenuProps {
  onSelect: (option: { title: string; description: string; value: string }) => void;
  itemOptions: { title: string; description: string; value: string }[];
}

const DropdownMenu = ({ onSelect, itemOptions }: DropdownMenuProps) => {
  return (
    <Menu as="div" className="relative inline-flex text-left">
      <Menu.Button
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => e.stopPropagation()}
        className="flex items-center justify-center h-7 w-7 rounded-lg bg-transparent hover:bg-blue-100 text-gray-400 hover:text-blue-500 focus:outline-none"
      >
        <span className="sr-only">Open options</span>
        <ChevronUpDownIcon className="text-blue-500" height={20} width={20} />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 top-7 z-10 w-56 origin-top-right rounded-lg bg-white transition focus:outline-none mt-3 shadow-2xl">
          <div className="flex flex-col p-1">
            {itemOptions.map((option, index) => (
              <Menu.Item key={index}>
                <button
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    onSelect(option);
                  }}
                  className="flex grow items-center bg-transparent hover:bg-blue-50 rounded-lg border-0 px-2 h-8 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <span className="text-sm text-gray-900 font-medium leading-4">{option.title}</span>
                </button>
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default DropdownMenu;