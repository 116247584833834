import { GeoAPI } from '@platform/api';

export type GeoApiTokenStorage = {
  token: string;
  createdAt: number;
  route: string;
};

export const geoApiStorageKey = (companyId: string) => `${companyId}:geoApiToken`;

export const retrieveGeoApiToken = (companyId: string): GeoApiTokenStorage | null => {
  const token = localStorage.getItem(geoApiStorageKey(companyId));
  if (!token) {
    return null;
  }

  // In case the token get corrupted and stores 'undefined'
  if (token === 'undefined') {
    clearGeoApiToken(companyId);
    return null;
  }

  try {
    const parsed = JSON.parse(token) as GeoApiTokenStorage;
    if (tokenIsValid(parsed)) {
      return parsed;
    } else {
      clearGeoApiToken(companyId);
      return null;
    }
  } catch {
    clearGeoApiToken(companyId);
    return null;
  }
};

export const storeGeoApiToken = (companyId: string, token: string | null | undefined) => {
  if (token) {
    const newToken: GeoApiTokenStorage = {
      token,
      createdAt: new Date().getTime(),
      route: GeoAPI.Client.AuthClient.route,
    };
    localStorage.setItem(geoApiStorageKey(companyId), JSON.stringify(newToken));
  } else {
    clearGeoApiToken(companyId);
  }
};

export const clearGeoApiToken = (companyId: string) => localStorage.removeItem(geoApiStorageKey(companyId));

// Refresh every 7 days
const tokenIsValid = (token: GeoApiTokenStorage) => {
  return (
    new Date().getTime() - token.createdAt < 7 * 24 * 60 * 60 * 1000 && GeoAPI.Client.AuthClient.route === token.route
  );
};
