import React, { useCallback, useMemo, useEffect, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { GeoAPI, PortalAPI } from '@platform/api';
import { withGeoJsonLoader } from '@platform/ui';
import { MapUI, Portal, StorageCache } from '@platform/ui-helpers';
import { MapRef } from 'react-map-gl';
import { Feature, Point } from 'geojson';
import { withAuthenticatedPageLayout } from '../Layout/authenticated-page';
import ProspectMap, { PendingProspect } from './Map';
import { FocusSpecification, SearchInputPlaceholder } from '@platform/ui';
import { ProspectFilter, toPosition } from '@platform/helpers';
import { State, useTypedDispatch } from '../../redux/state';
import { fetchMapBounds, getSearchSummary, loadCampaignDetails } from '../../redux/thunks';
import { AppSelectors, Selectors } from '../../redux/selectors';
import { useSelector } from 'react-redux';
import { useSetInitialBoundingBox, HandleSetBoundingBoxFn } from '../../hooks/useSetInitialBoundingBox';
import { intersection } from 'lodash';
import { Actions } from '../../redux/actions';
import FiltersBottomSheet from './BottomSheet/Filters';
import './index.css';
import { ProspectsActions } from '../../redux/actions/prospect-actions';
import SearchBottomSheet from './BottomSheet/SearchBottomSheet';
import { match } from 'ts-pattern';
import { useLocation } from 'react-router-dom';
import { useCampaignNavigation, QueryParams, parseQueryParams } from './utils';
import bbox from '@turf/bbox';
import CampaignBottomSheet from './BottomSheet/CampaignBottomSheet';

type UseProspectFiltersInjectedProps = {
  onSetFilters: (filter: ProspectFilter) => void;
  onClearFilters: () => void;
  filters: ProspectFilter | null;
  filterCount: number;
  isFiltered: (
    prospect: Pick<
      PortalAPI.ProspectAPI.ProspectResponse_v3,
      'leadStatus' | 'assignedTo' | 'prospectTags' | 'createdAt'
    >
  ) => boolean;
};

const location: [number, number] = [-85.62170101439055, 42.923629];

const transform = <T extends any, O extends any = any, E extends T = any>(obj: E, fn: (v: T) => O) => {
  return fn(obj as T);
};

const normalizeFilter = (filter: Partial<ProspectFilter>): ProspectFilter => {
  return {
    assignedToUserIds: [],
    leadStatusIds: [],
    tagIds: [],
    dates: null,
    ...filter,
  };
};

const useProspectFilters = (
  onSetCallback?: (filter: ProspectFilter) => void,
  onClearCallback?: () => void,
  persistence?: StorageCache<ProspectFilter>
): UseProspectFiltersInjectedProps => {
  const dispatch = useTypedDispatch();
  const filters = useSelector(Selectors.prospectFilters);

  const onSetFilters = useCallback(
    async (payload: ProspectFilter) => {
      dispatch(ProspectsActions.setFilters(payload));
      onSetCallback && onSetCallback(payload);
    },
    [onSetCallback, dispatch]
  );

  const onClearFilters = useCallback(async () => {
    dispatch(ProspectsActions.clearFilters());
    onClearCallback && onClearCallback();
  }, [onClearCallback, dispatch]);

  useEffect(() => {
    if (filters && persistence) {
      persistence.set(normalizeFilter(filters));
    }
  }, [filters, persistence]);

  useEffect(() => {
    if (persistence) {
      persistence.get().then((filter) => {
        filter && onSetFilters(normalizeFilter(filter));
      });
    }
  }, [onClearFilters, onSetFilters, persistence]);

  const filterCount = useMemo(
    () =>
      (Object.keys(filters ?? {}) as (keyof ProspectFilter)[]).reduce<number>((count, key) => {
        const f = filters ? filters[key] : null;
        if (!f) {
          return count;
        }

        if (Array.isArray(f)) {
          if (f.length > 0) {
            count++;
          }
        } else {
          count++;
        }
        return count;
      }, 0),
    [filters]
  );

  const isFiltered: UseProspectFiltersInjectedProps['isFiltered'] = useCallback(
    (prospect) => {
      let filtered = false;
      if (filterCount === 0) {
        return false;
      }

      if (filters?.assignedToUserIds?.length) {
        filtered ||= !(prospect.assignedTo?.id && filters.assignedToUserIds.includes(prospect.assignedTo.id));
      }

      if (filters?.leadStatusIds?.length) {
        filtered ||= !filters.leadStatusIds.includes(prospect.leadStatus?.id ?? null);
      }

      if (filters?.tagIds?.length) {
        filtered ||= !(
          prospect.prospectTags.length &&
          intersection(
            prospect.prospectTags.map((t) => t.externalId),
            filters.tagIds
          ).length
        );
      }

      if (filters?.dates) {
        const date = new Date(prospect.leadStatus?.createdAt ?? prospect.createdAt);
        filtered ||= !!(filters.dates.after && filters.dates.after > date.getTime());
        filtered ||= !!(filters.dates.before && filters.dates.before < date.getTime());
      }

      return filtered;
    },
    [filterCount, filters?.assignedToUserIds, filters?.dates, filters?.leadStatusIds, filters?.tagIds]
  );

  const normalizedFilters = useMemo(() => (filters ? normalizeFilter(filters) : null), [filters]);

  const injected: UseProspectFiltersInjectedProps = useMemo(
    () => ({
      onSetFilters,
      onClearFilters,
      filters: normalizedFilters,
      filterCount,
      isFiltered,
    }),
    [onSetFilters, onClearFilters, normalizedFilters, filterCount, isFiltered]
  );

  return injected;
};

const MapContents = withGeoJsonLoader(
  ({ setMap, reload: reloadCache, onMapViewportChange, setFeatureState, handleSetMapFilter, handleClearMapFilter }) => {
    const { filters, onSetFilters } = useProspectFilters(
      handleSetMapFilter(MapUI.Sources.PROSPECTS),
      handleClearMapFilter(MapUI.Sources.PROSPECTS)
    );

    const selectedProspect = useSelector(Selectors.getSelectedProspect);
    const [currentBoundingBox, setCurrentBoundingBox] = useState<FocusSpecification>();
    const [searchCenterProximity, setSearchCenterProximity] = useState<{ lat: number; lng: number }>();
    const [pendingProspect, setPendingProspect] = useState<PendingProspect | null>(null);
    const [searchOpened, setSearchOpened] = useState(false);
    const [filtersOpened, setFiltersOpened] = useState(false);
    const [campaignOpened, setCampaignOpened] = useState(false);
    const [selectedId, setSelectedId] = useState<string>('');
    const [fetchSummary, setFetchSummary] = useState(false);
    const [showCampaignPreview, setShowCampaignPreview] = useState(true);
    const [mode, setMode] = useState<QueryParams | null>(null);

    const initialProspectModalDimensions = useRef<[number, number] | null>(null);

    const dispatch = useTypedDispatch();
    const { getAccessTokenSilently } = useAuth0();
    const { initiateMode } = useCampaignNavigation();
    const { search } = useLocation();

    const mapBounds = useSelector(Selectors.mapBounds);
    const searchSummary = useSelector(
      (state) => selectedId && Selectors.prospectSearchSummary(selectedId)(state as State)
    );

    // callbacks
    const handleSearchExit = useCallback(() => setSearchOpened(false), []);
    const handleFilterExit = useCallback(() => setFiltersOpened(false), []);
    const onSearchBottomSheetWillDismiss = useCallback(() => {
      handleSearchExit();
    }, [handleSearchExit]);
    const onProspectSelected = useCallback(
      (feature: Feature<Point, GeoAPI.ProspectGeoJsonProperties>, extra?: { zoom?: number; jump?: boolean }) => {
        setCurrentBoundingBox({
          type: 'center',
          center: feature.geometry.coordinates as [number, number],
          paddingBottom: initialProspectModalDimensions.current ? initialProspectModalDimensions.current[1] : 400,
          focal: 'prospect',
          focalId: feature.properties.id,
          ...(extra ? { moveType: extra.jump ? 'jump' : 'fly', zoom: extra.zoom } : {}),
        });
        dispatch(
          Actions.App.setProspectsDetailsModal({
            isOpen: true,
            campaignId: feature.properties.id,
            prospectId: feature.properties.id,
          })
        );
        dispatch(Actions.Prospects.selectProspect({ id: feature.properties.id, data: feature }));
      },
      [dispatch]
    );
    const handleMapEffectOnSelection = useCallback(
      (searchSummary: any) => {
        if (searchSummary && searchSummary.data) {
          const d = searchSummary.data;
          if (d.type === 'prospect') {
            const selected = d as PortalAPI.ProspectAPI.ProspectSearchRetrieveResponse<typeof d.type>;
            onProspectSelected(selected.feature, { jump: true, zoom: 18 });
          } else if (d.type === 'address' || d.type === 'poi') {
            const selected = d as PortalAPI.ProspectAPI.ProspectSearchRetrieveResponse<typeof d.type>;

            setCurrentBoundingBox({
              type: 'center',
              center: toPosition(selected.location),
              focalId: d.id,
              paddingBottom: 0,
              focal: 'location',
              moveType: 'jump',
              zoom: 18,
            });

            setPendingProspect({
              id: selected.id,
              location: { lat: selected.location.coordinates[1], lng: selected.location.coordinates[0] },
              ...match(selected)
                .with({ type: 'address' }, () =>
                  transform<
                    PortalAPI.ProspectAPI.ProspectSearchRetrieveResponse<'address'>,
                    Omit<PendingProspect, 'location' | 'id'>
                  >(selected, (t) => ({
                    line1: t.address,
                    line2: `${t.city}, ${t.state} ${t.zip}`,
                    prospectAddress: t.isValidLocation
                      ? {
                          address1: t.address,
                          city: t.city,
                          state: t.state,
                          zip: t.zip,
                          formatted: t.formatted,
                        }
                      : null,
                  }))
                )
                .with({ type: 'poi' }, () =>
                  transform<
                    PortalAPI.ProspectAPI.ProspectSearchRetrieveResponse<'poi'>,
                    Omit<PendingProspect, 'location' | 'id'>
                  >(selected, (t) => ({
                    line1: t.name,
                    line2: selected.formatted,
                    prospectAddress: {
                      address1: t.address,
                      city: t.city,
                      state: t.state,
                      zip: t.zip,
                      formatted: t.formatted,
                    },
                  }))
                )
                .exhaustive(),
            });
          } else if (d.type === 'place') {
            const selected = d as PortalAPI.ProspectAPI.ProspectSearchRetrieveResponse<typeof d.type>;

            setCurrentBoundingBox({
              ...(selected.bbox
                ? { type: 'bounds', bounds: selected.bbox }
                : { type: 'center', center: toPosition(selected.location), zoom: 13 }),
              focal: 'location',
              focalId: d.id || '',
              moveType: 'jump',
              paddingBottom: 0,
            });
            setPendingProspect({
              id: selected.id,
              location: { lat: selected.location.coordinates[1], lng: selected.location.coordinates[0] },
              prospectAddress: null,
              line1: selected.name,
              line2: selected.description,
            });
          }
        }
      },
      [onProspectSelected]
    );
    const handleSearchSelection = useCallback(
      async <T extends keyof PortalAPI.ProspectAPI.ProspectSearchTypes>(
        type: T,
        data: PortalAPI.ProspectAPI.ProspectSearchTypes[T]
      ) => {
        setSelectedId(data.id);
        if (type === 'prospect') {
          const d = data as PortalAPI.ProspectAPI.ProspectSearchTypes['prospect'];
          onProspectSelected(d.feature, { jump: true, zoom: 18 });
          handleSearchExit();
        } else {
          try {
            await dispatch(getSearchSummary(getAccessTokenSilently, { suggestionId: data.id }));
            setFetchSummary(true);
            handleSearchExit();
          } catch (e: any) {
            console.error(e);
          }
        }
      },
      [onProspectSelected, getAccessTokenSilently, dispatch, handleSearchExit]
    );

    const handleSetInitialBoundingBox: HandleSetBoundingBoxFn = useCallback((p: any) => {
      setCurrentBoundingBox({
        ...p,
        paddingBottom: 100,
        focal: 'location',
        focalId: '',
        moveType: 'jump',
        ...(p.type === 'center' ? { zoom: 18 } : {}),
      });
    }, []);

    useSetInitialBoundingBox(mapBounds, handleSetInitialBoundingBox);

    const onMapClicked = useCallback(() => {
      console.log('onMapClicked');
    }, []);

    const onMarkerCreated = useCallback(
      async (location: {
        lngLat: { lng: number; lat: number };
        address?: { address1: string; city: string; state: string; zip: string; formatted: string };
      }) => {
        console.log('onMarkerCreated');
      },
      []
    );

    const onReloadMapData = useCallback(() => {
      reloadCache();
    }, [reloadCache]);

    const handleMapViewportChange = useCallback(
      (map: mapboxgl.Map | MapRef, zoom: number) => {
        // If the user is zoomed out, then the focal point of the map
        // is insignificant
        if (map.getCenter().lat !== 0) {
          if (zoom > 7) {
            setSearchCenterProximity(map.getCenter());
          }
          onMapViewportChange(map, zoom);
        }
      },
      [onMapViewportChange]
    );

    const handlePendingProspectDismiss = useCallback(() => {
      setPendingProspect(null);
    }, []);

    const onCampaignSelected = useCallback(
      ({ id }: Feature<Point, GeoAPI.CampaignGeoJsonProperties>) => {
        initiateMode({ mode: 'territory-preview', campaignId: id as string });
        setShowCampaignPreview(true);
        setPendingProspect(null);
      },
      [initiateMode]
    );

    const onCampaignClosed = useCallback(() => {
      setShowCampaignPreview(false);
      setCampaignOpened(false);
      initiateMode(null);
    }, [initiateMode]);

    const onViewTerritory = useCallback(() => {
      setShowCampaignPreview(false);
    }, []);

    const campaignId = String(mode?.campaignId || 0);

    const shouldLoadCampaign =
      (mode &&
        mode.campaignId &&
        mode.mode === 'territory-preview' &&
        currentBoundingBox?.focalId !== mode.campaignId) ||
      false;

    const [campaign, setCampaign] = useState<PortalAPI.CampaignAPI.Responses['GET /v2/campaigns/:id'] | undefined>();

    useEffect(() => {
      if (search) {
        setMode(parseQueryParams(new URLSearchParams(search)));
      }
    }, [search]);

    useEffect(() => {
      if (shouldLoadCampaign && campaignId && campaignId !== '0') {
        setCampaign(undefined);

        dispatch(loadCampaignDetails(campaignId, getAccessTokenSilently))
          .then((result) => {
            setCampaign(result);
          })
          .catch(() => {
            setCampaign(undefined);
          });
      }
    }, [shouldLoadCampaign, campaignId, getAccessTokenSilently, dispatch]);

    const campaignBbox: [[number, number], [number, number]] | undefined = useMemo(() => {
      const geometry = campaign?.shape?.features[0].geometry;
      if (
        geometry &&
        mode?.campaignId &&
        mode?.mode === 'territory-preview' &&
        mode.campaignId === campaign?.externalTrackingId
      ) {
        const [a, b, c, d] = bbox(geometry);
        return [
          [a, b],
          [c, d],
        ];
      }
    }, [campaign, mode?.campaignId, mode?.mode]);

    const selectedCampaign = useMemo(() => {
      const c = mode?.campaignId && campaign?.externalTrackingId === mode.campaignId ? campaign : undefined;
      return c;
    }, [campaign, mode?.campaignId]);

    useEffect(() => {
      // Wait for the modal to expand to avoid jittery map movements
      if (
        campaignBbox &&
        selectedCampaign &&
        showCampaignPreview &&
        selectedCampaign.externalTrackingId !== currentBoundingBox?.focalId
      ) {
        setCurrentBoundingBox({
          type: 'bounds',
          bounds: campaignBbox,
          paddingBottom: 0,
          focal: 'campaign',
          focalId: selectedCampaign.externalTrackingId,
        });
      }
    }, [campaignBbox, selectedCampaign, showCampaignPreview, currentBoundingBox?.focalId]);

    // use effects
    useEffect(() => {
      if (!mapBounds) {
        dispatch(fetchMapBounds(getAccessTokenSilently, location));
      }
    }, [dispatch, getAccessTokenSilently, mapBounds]);

    useEffect(() => {
      if (fetchSummary) {
        handleMapEffectOnSelection(searchSummary);
      }
    }, [fetchSummary, searchSummary, handleMapEffectOnSelection]);

    useEffect(() => {
      if (showCampaignPreview && mode?.mode === 'territory-preview') {
        setCampaignOpened(true);
      }
    }, [showCampaignPreview, mode?.mode]);

    return (
      <>
        <ProspectMap
          zoomProp={4}
          onMapClicked={onMapClicked}
          onMarkerCreated={onMarkerCreated}
          onMapLoaded={setMap}
          currentBoundingBox={currentBoundingBox}
          onMapViewportChange={handleMapViewportChange}
          onReloadMapData={onReloadMapData}
          enableRecenterControl={true}
          onProspectSelected={onProspectSelected}
          onCampaignSelected={onCampaignSelected}
          pendingProspect={pendingProspect}
          onPendingProspectDismiss={handlePendingProspectDismiss}
          selectedProspect={selectedProspect ? selectedProspect.data : null}
          setFeatureState={setFeatureState}
          showCampaignLayers={true}
          campaignShape={selectedCampaign?.shape ?? null}
        />
        {campaignOpened && (
          <CampaignBottomSheet
            show={campaignOpened}
            isLoading={false}
            onExit={onCampaignClosed}
            campaign={selectedCampaign}
            onViewCampaign={onViewTerritory}
          />
        )}
        <div className="search-box">
          <SearchInputPlaceholder
            onSearchClick={() => setSearchOpened(true)}
            onFilterClick={() => setFiltersOpened(true)}
            placeholder="Search addresses or prospects"
          />
        </div>

        {searchOpened && (
          <>
            <SearchBottomSheet
              show={searchOpened}
              onExit={handleSearchExit}
              onSelect={handleSearchSelection}
              mapCenter={searchCenterProximity}
              onBottomSheetWillDismiss={onSearchBottomSheetWillDismiss}
            />
          </>
        )}

        {filtersOpened && (
          <>
            <FiltersBottomSheet
              show={filtersOpened}
              filters={filters}
              onExit={handleFilterExit}
              onApplyFilters={onSetFilters}
            />
          </>
        )}
      </>
    );
  }
);

const ProspectsMapPage = withAuthenticatedPageLayout(() => {
  const geoApiToken = useSelector(AppSelectors.geoApiToken);

  const sources: Extract<MapUI.Sources, MapUI.Sources.CAMPAIGNS | MapUI.Sources.PROSPECTS>[] = useMemo(() => {
    return [MapUI.Sources.PROSPECTS, MapUI.Sources.CAMPAIGNS];
  }, []);

  const getGeoApiToken = useCallback(() => Promise.resolve(geoApiToken?.token ?? null), [geoApiToken?.token]);

  return geoApiToken?.token ? (
    <MapContents
      geoApiUrl={Portal.getEnvironment(process.env, 'REACT_APP_GEO_API_URL')}
      getAccessToken={getGeoApiToken}
      sources={sources}
    />
  ) : (
    <></>
  );
});

export default ProspectsMapPage;
