import { useAuth0 } from '@auth0/auth0-react';
import { PortalAPI } from '@platform/api';
import { Drawer, SearchInput, SearchMenu, useProspectSearch } from '@platform/ui';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTypedDispatch } from '../../../../redux/state';
import { searchProspects } from '../../../../redux/thunks';
import { toPoint } from '@platform/helpers';
import { ProspectSearchTypes } from '@platform/api/dist/portal-api/prospect-api.types';
import { useSelector } from 'react-redux';
import { Selectors } from '../../../../redux/selectors';
import { State } from '../../../../redux/state';

type SearchTypes = PortalAPI.ProspectAPI.ProspectSearchTypes;

interface SearchBottomSheetProps {
  onBottomSheetPresented?: (height: number, width: number) => void;
  onBottomSheetDismissed?: () => void;
  onBottomSheetWillDismiss?: () => void;
  onSelect: <T extends keyof SearchTypes>(type: T, data: SearchTypes[T]) => Promise<void>;
  onExit: () => void;
  show: boolean;
  mapCenter?: { lng: number; lat: number };
}

interface ModalHeaderProps {
  onCancel: () => void;
  input: React.ReactElement;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({ onCancel, input }) => (
  <div className="flex items-center w-full space-x-3 pl-3">
    {input}
    <button
      onClick={onCancel}
      className="shrink-0 pr-3 bg-white text-base border-none py-2 rounded-lg outline-none text-blue-500 active:text-blue-400"
    >
      <span className="font-semibold">Cancel</span>
    </button>
  </div>
);

const SearchBottomSheet: React.FC<SearchBottomSheetProps> = ({
  show,
  onExit,
  onSelect,
  mapCenter,
  onBottomSheetPresented,
  onBottomSheetDismissed,
  onBottomSheetWillDismiss,
}) => {
  const dispatch = useTypedDispatch();
  const auth0 = useAuth0();

  const locationRef = useRef<{ lng: number; lat: number } | null>(null);
  const mapCenterRef = useRef<{ lng: number; lat: number } | null>(null);
  const currentSearchRef = useRef<string>('');

  const [searchLoading, setSearchLoading] = useState(false);
  const [searchError, setSearchError] = useState<string | null>(null);
  const [currentSearchTerm, setCurrentSearchTerm] = useState('');

  // Get search results from selector
  const searchResults = useSelector((state) =>
    currentSearchTerm ? Selectors.prospectSearchData(currentSearchTerm)(state as State) : null
  );

  // Handle map center updates
  useEffect(() => {
    mapCenterRef.current = mapCenter ?? null;
  }, [mapCenter]);

  // Handle bottom sheet presentation
  useEffect(() => {
    if (show && onBottomSheetPresented) {
      onBottomSheetPresented(window.innerHeight * 0.8, window.innerWidth);
    }
  }, [show, onBottomSheetPresented]);

  const performSearch = useCallback(
    async (text: string) => {
      if (!text) {
        setSearchLoading(false);
        setSearchError(null);
        setCurrentSearchTerm('');
        return null;
      }

      // Check if the current search term is the same as the previous one
      if (currentSearchRef.current === text) {
        return searchResults?.data || null;
      }

      const body = {
        text,
        fields: ['prospect', 'address', 'poi', 'place'] as (keyof ProspectSearchTypes)[],
        limit: 8,
        ...(locationRef.current
          ? { location: toPoint(locationRef.current) }
          : mapCenterRef.current
          ? { location: toPoint(mapCenterRef.current) }
          : {}),
      };

      try {
        setSearchLoading(true);
        setSearchError(null);
        currentSearchRef.current = text;
        setCurrentSearchTerm(text);

        await dispatch(searchProspects(auth0.getAccessTokenSilently, body));

        if (currentSearchRef.current === text) {
          setSearchLoading(false);
        }

        return searchResults?.data || null;
      } catch (error) {
        console.error('Search error:', error);
        if (currentSearchRef.current === text) {
          setSearchLoading(false);
          setSearchError(error as string);
        }
        return null;
      }
    },
    [dispatch, auth0.getAccessTokenSilently, searchResults?.data]
  );

  const handleSearchItemSelected = useCallback(
    async <T extends keyof SearchTypes>(type: T, data: SearchTypes[T]) => {
      try {
        await onSelect(type, data);
      } catch (error) {
        console.error('Error selecting search item:', error);
        setSearchError(error as string);
      }
    },
    [onSelect]
  );

  const {
    searchResults: prospectSearchResults,
    searchIsFetching,
    onValueChanged, // Debounced value change handler
    onSearchItemSelected,
    onClear,
  } = useProspectSearch({
    onSearch: performSearch,
    debounce: { ms: 300, maxWaitMs: 1000 }, // Increase debounce ms to avoid frequent calls
    handleSearchItemSelected,
  });

  const handleOnCancel = useCallback(() => {
    onClear();
    setSearchError(null);
    setCurrentSearchTerm('');
    if (onBottomSheetWillDismiss) {
      onBottomSheetWillDismiss();
    }
    onExit();
    if (onBottomSheetDismissed) {
      onBottomSheetDismissed();
    }
  }, [onClear, onExit, onBottomSheetWillDismiss, onBottomSheetDismissed]);

  const finalSearchResults = prospectSearchResults || searchResults?.data;

  return (
    <Drawer isOpen={show} onClose={handleOnCancel}>
      <div className="grid gap-2 pt-2">
        <ModalHeader
          onCancel={handleOnCancel}
          input={
            <SearchInput>
              <input
                className="grow text-gray-900 bg-gray-50 placeholder:text-gray-500 pl-4 pr-3 text-sm md:text-base outline-none border-none"
                placeholder="e.g. 1424 Adams St or John Doe"
                type="text"
                name="search"
                onChange={(e) => onValueChanged(e.target.value?.toString().trim() ?? '')}
                autoComplete="off"
              />
            </SearchInput>
          }
        />
        <div className="flex flex-col px-2 pb-2">
          <SearchMenu
            data={finalSearchResults}
            onSelect={onSearchItemSelected}
            isLoading={searchLoading || searchIsFetching}
            error={searchError}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default SearchBottomSheet;
