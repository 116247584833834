import { AppReducer } from '../types/reducer-types';
import { initialState } from '../state';
import { AppState } from '../state/app-state';
import { Constants } from '@platform/app-config';
import { clearSubscriptionStatusTimestamp, saveSubscriptionStatusTimestamp } from '../../utils/subscription-error';
import { clearGeoApiToken } from '../../utils/geo-api-storage';

export const appReducer: AppReducer<'App'> = (state = initialState.App, action): typeof state => {
  switch (action.type) {
    case 'Notification/add':
      return {
        ...state,
        notifications: [...state.notifications, action.payload],
      };
    case 'Notification/remove':
      return {
        ...state,
        notifications: state.notifications.slice(1),
      };
    case 'Notification/byId':
      return {
        ...state,
        notifications: state.notifications.filter(({ id }) => id !== action.payload),
      };
    case 'Modals/setTeamInviteModal':
      return {
        ...state,
        teamInviteModalIsOpen: action.payload,
      };
    case 'Modals/setCompanyInviteModal':
      return {
        ...state,
        companyInviteModalIsOpen: action.payload.isOpen,
      };
    case 'Modals/setProspectsDetailsModal': {
      const { isOpen, campaignId, prospectId } = action.payload;
      return {
        ...state,
        prospectsDetailsModalIsOpen:
          isOpen && campaignId && prospectId !== null
            ? {
                campaignId,
                prospectId,
                boostIsOpen: false,
                infoIsOpen: false,
                contactIsOpen: false,
                addressIsOpen: false,
              }
            : null,
      };
    }
    case 'Modals/setProspectsContactModal': {
      return {
        ...state,
        prospectsDetailsModalIsOpen: state.prospectsDetailsModalIsOpen
          ? {
              ...state.prospectsDetailsModalIsOpen,
              contactIsOpen: !!action.payload,
            }
          : null,
      };
    }
    case 'Modals/setProspectInfoModal': {
      return {
        ...state,
        prospectsDetailsModalIsOpen: state.prospectsDetailsModalIsOpen
          ? {
              ...state.prospectsDetailsModalIsOpen,
              infoIsOpen: !!action.payload,
            }
          : null,
      };
    }
    case 'Modals/setProspectAddressModal': {
      return {
        ...state,
        prospectsDetailsModalIsOpen: state.prospectsDetailsModalIsOpen
          ? {
              ...state.prospectsDetailsModalIsOpen,
              addressIsOpen: !!action.payload,
            }
          : null,
      };
    }
    case 'Modals/setDuplicateProspectModal': {
      return {
        ...state,
        duplicateAddressModalIsOpen: action.payload,
      };
    }
    case 'Modals/setProspectsTagsModal': {
      return {
        ...state,
        prospectsTagsModalIsOpen: action.payload,
      };
    }
    case 'Modals/setBoostIndicatorModal':
      return {
        ...state,
        prospectsDetailsModalIsOpen: state.prospectsDetailsModalIsOpen
          ? {
              ...state.prospectsDetailsModalIsOpen,
              boostIsOpen: action.payload.isOpen,
            }
          : null,
      };
    case 'Modals/setCampaignAssignmentModal':
      return {
        ...state,
        campaignAssignmentModal: action.payload.isOpen
          ? {
              assignedUserId: action.payload.assignedUserId,
              campaignId: action.payload.campaignId,
            }
          : null,
      };
    case 'Modals/setSubscriptionErrorModal':
      return {
        ...state,
        subscriptionErrorModalIsOpen: action.payload,
      };
    case 'Modals/setProspectsNotesModal': {
      return {
        ...state,
        prospectsNotesModalIsOpen: action.payload,
      };
    }
    case 'Modals/setNewAppointmentModal': {
      return {
        ...state,
        prospectsNewAppointmentModalIsOpen: action.payload,
      };
    }
    case 'Modals/setEditTagModal': {
      return {
        ...state,
        editTagModalIsOpen: action.payload,
      };
    }
    case 'Modals/setCreateTagModal': {
      return {
        ...state,
        createTagModalIsOpen: action.payload.open,
      };
    }
    case 'Modals/setDeleteTagModal': {
      return {
        ...state,
        deleteTagModalIsOpen: action.payload,
      };
    }
    case 'Modals/setHideTagModal': {
      return {
        ...state,
        hideTagModalIsOpen: action.payload,
      };
    }
    case 'App/setCompanyImpersonation': {
      const companyImpersonation: AppState['companyImpersonation'] = {
        id: action.payload.companyId.toString(),
        time: new Date(),
      };
      window.sessionStorage.setItem(Constants.IMPERSONATE_COMPANY_ID_HEADER, JSON.stringify(companyImpersonation));

      return {
        ...state,
        companyImpersonation,
      };
    }
    case 'App/clearCompanyImpersonation':
      window.sessionStorage.removeItem(Constants.IMPERSONATE_COMPANY_ID_HEADER);
      return {
        ...state,
        companyImpersonation: null,
      };
    case 'User/logout': {
      window.sessionStorage.removeItem(Constants.IMPERSONATE_COMPANY_ID_HEADER);
      clearSubscriptionStatusTimestamp();
      if (action.payload) {
        clearGeoApiToken(action.payload.companyId);
      }
      return {
        ...state,
        geoApiToken: null,
      };
    }
    case 'App/didRedirectToGettingStarted': {
      return {
        ...state,
        didRedirectToGettingStarted: true,
      };
    }
    case 'App/setSubscriptionStatusLoading': {
      return {
        ...state,
        subscriptionStatus: {
          ...state.subscriptionStatus,
          isLoading: true,
        },
      };
    }
    case 'App/setSubscriptionStatus': {
      if (action.payload.lastChecked === null) {
        clearSubscriptionStatusTimestamp();
      } else {
        saveSubscriptionStatusTimestamp(action.payload.lastChecked);
      }
      return {
        ...state,
        subscriptionStatus: {
          ...state.subscriptionStatus,
          ...action.payload,
          isLoading: false,
        },
      };
    }
    case 'App/setMapBounds': {
      return {
        ...state,
        mapBounds: {
          ...action.payload,
          isLoading: false,
        },
      };
    }
    case 'Modals/setActivityFeedModal': {
      return {
        ...state,
        activityFeedModalIsOpen: action.payload,
      };
    }
    case 'Modals/setActivityDetailsModal': {
      return {
        ...state,
        activityDetailModal: action.payload,
      };
    }
    case 'GeoAPI/tokenLoading': {
      return {
        ...state,
        geoApiToken: {
          token: null,
          isLoading: true,
        },
      };
    }
    case 'GeoAPI/tokenLoaded': {
      return {
        ...state,
        geoApiToken: {
          token: action.payload,
          isLoading: false,
        },
      };
    }
    default:
      return state;
  }
};
