import { uniq } from 'lodash';
import { AppReducer } from '../types/reducer-types';
import { initialState } from '../state';
import { initialProspectsState, ProspectsState } from '../state/prospects-state';
import { Utils } from '@platform/ui-helpers';
import {
  ProspectSearchRetrieveResponse,
  ProspectSearchSummaryResponse,
} from '@platform/api/dist/portal-api/prospect-api.types';

export const prospectsReducer: AppReducer<'Prospects'> = (state = initialState.Prospects, action): typeof state => {
  switch (action.type) {
    case 'Prospects/campaign-loaded': {
      return {
        ...state,
        prospects: {
          ...state.prospects,
          ...action.payload.prospects.reduce<Record<string, ProspectsState['prospects'][number]>>((acc, p) => {
            acc[p.externalTrackingId] = {
              ...p,
              createdAt: new Date(p.createdAt),
              isLoading: false,
            };
            return acc;
          }, {}),
        },
        prospectsByCampaign: {
          ...state.prospectsByCampaign,
          [action.payload.campaignId]: {
            totalRecords: action.payload.totalCount,
            ids: uniq([
              ...(state.prospectsByCampaign[action.payload.campaignId]?.ids ?? []),
              ...action.payload.prospects.map((p) => p.externalTrackingId),
            ]),
          },
        },
      };
    }
    case 'Prospects/loaded': {
      return {
        ...state,
        prospects: {
          ...state.prospects,
          [action.payload.id]: {
            ...(action.payload.data ?? state.prospects[action.payload.id]),
            isLoading: false,
          },
        },
      };
    }
    case 'Prospects/loading': {
      return {
        ...state,
        prospects: {
          ...state.prospects,
          [action.payload.externalId]: {
            ...state.prospects[action.payload.externalId],
            isLoading: true,
          },
        },
      };
    }
    case 'Prospects/setProspectLeadStatus': {
      const { prospectId, leadStatus, userId } = action.payload;

      return {
        ...state,
        prospects: {
          ...state.prospects,
          [prospectId]: {
            ...state.prospects[prospectId],
            leadStatus: leadStatus
              ? {
                  createdAt: new Date(),
                  addedByUserId: userId ?? null,
                  ...leadStatus,
                }
              : null,
          },
        },
      };
    }
    case 'Prospects/setProspectNotes': {
      const { prospectId, ...rest } = action.payload;

      return {
        ...state,
        prospects: {
          ...state.prospects,
          [prospectId]: {
            ...state.prospects[prospectId],
            ...rest,
          },
        },
      };
    }
    case 'Prospects/setProspectMailStatus': {
      const { prospectId, status } = action.payload;
      return {
        ...state,
        prospects: {
          ...state.prospects,
          [prospectId]: {
            ...state.prospects[prospectId],
            ...(status === 'start' ? { mailActivatedAt: new Date() } : { mailRemovedAt: new Date() }),
            mailStatus: Utils.calculateMailStatus(state.prospects[prospectId].mailStatus, status),
          },
        },
      };
    }
    case 'Prospects/clear':
      return {
        ...initialProspectsState,
      };

    case 'Prospects/removeProspect': {
      const { prospectId, campaignId } = action.payload;
      return {
        ...state,
        prospects: (Object.keys(state.prospects) as `${number}`[]).reduce(
          (acc, key) => {
            if (key !== prospectId) {
              acc[key] = state.prospects[key];
            }
            return acc;
          },
          { ...state.prospects }
        ),
        prospectsByCampaign: {
          ...state.prospectsByCampaign,
          [campaignId]: {
            ...state.prospectsByCampaign[campaignId],
            ids: state.prospectsByCampaign[campaignId]?.ids.filter((id) => id !== prospectId),
            totalRecords: state.prospectsByCampaign[campaignId]?.totalRecords - 1,
          },
        },
      };
    }
    case 'Prospects/removeProspectTag': {
      return {
        ...state,
        prospects: {
          ...state.prospects,
          [action.payload.prospectId]: {
            ...state.prospects[action.payload.prospectId],
            prospectTags: state.prospects[action.payload.prospectId].prospectTags.filter(
              (t) => t.externalId !== action.payload.tagId
            ),
          },
        },
      };
    }
    case 'Prospects/setProspectTags': {
      const { tags, prospectId } = action.payload;

      return {
        ...state,
        prospects: {
          ...state.prospects,
          [prospectId]: {
            ...state.prospects[prospectId],
            prospectTags: tags.map((t) => ({ ...t, id: 0 })), // ultimately integer IDs are not important
          },
        },
      };
    }
    case 'Prospects/setProspectContact': {
      const prospect = action.payload;

      return {
        ...state,
        prospects: {
          ...state.prospects,
          [prospect.externalTrackingId]: {
            ...state.prospects[prospect.externalTrackingId],
            name: prospect.name,
            emailAddress: prospect.emailAddress,
            phoneNumber: prospect.phoneNumber,
            secondaryPhoneNumber: prospect.secondaryPhoneNumber,
          },
        },
      };
    }
    case 'Prospects/setProspectAddress': {
      const prospect = action.payload;

      return {
        ...state,
        prospects: {
          ...state.prospects,
          [prospect.externalTrackingId]: {
            ...state.prospects[prospect.externalTrackingId],
            address: {
              ...prospect.address,
            },
          },
        },
      };
    }
    case 'Prospects/setProspectAssignedTo': {
      const { prospectId, assignedTo } = action.payload;

      return {
        ...state,
        prospects: {
          ...state.prospects,
          [prospectId]: {
            ...state.prospects[prospectId],
            assignedTo,
          },
        },
      };
    }
    case 'Prospects/setProspectAppointment': {
      const { appointmentTime, prospectId } = action.payload;

      return {
        ...state,
        prospects: {
          ...state.prospects,
          [prospectId]: {
            ...state.prospects[prospectId],
            prospectAppointment: {
              appointmentTime,
            },
          },
        },
      };
    }
    case 'Prospects/removeProspectAppointment': {
      const { prospectId } = action.payload;

      return {
        ...state,
        prospects: {
          ...state.prospects,
          [prospectId]: {
            ...state.prospects[prospectId],
            prospectAppointment: null,
          },
        },
      };
    }
    case 'Prospects/setActivity': {
      const { prospectId, activity } = action.payload;

      return {
        ...state,
        activity: {
          ...state.activity,
          [prospectId]: {
            isLoading: false,
            data: activity.map((a) => ({
              ...a,
              timestamp: new Date(a.timestamp),
            })),
          },
        },
      };
    }
    case 'Prospects/setActivityLoading': {
      const { prospectId } = action.payload;

      return {
        ...state,
        activity: {
          ...state.activity,
          [prospectId]: {
            ...state.activity[prospectId],
            isLoading: true,
          },
        },
      };
    }
    case 'Prospects/profundPropertyDataLoading': {
      return {
        ...state,
        prospects: {
          ...state.prospects,
          [action.payload.externalId]: {
            ...state.prospects[action.payload.externalId],
            profundPropertyDataLoading: true,
          },
        },
      };
    }
    case 'Prospects/profundPropertyDataLoaded': {
      return {
        ...state,
        prospects: {
          ...state.prospects,
          [action.payload.externalId]: {
            ...state.prospects[action.payload.externalId],
            profundPropertyDataLoading: false,
            profundPropertyData: action.payload.data?.profundPropertyData ?? null,
            ...(action.payload.data?.name ? { name: action.payload.data.name } : {}),
          },
        },
      };
    }
    case 'Prospects/searchDataLoading': {
      const { externalId } = action.payload;

      return {
        ...state,
        prospects: {
          ...state.prospects,
        },
        activity: {
          ...state.activity,
          [externalId]: {
            data: [],
            isLoading: true,
          },
        },
      };
    }
    case 'Prospects/searchDataLoaded': {
      const { externalId, search, data } = action.payload;

      return {
        ...state,
        searchProspects: {
          ...state.searchProspects,
          [search]: {
            data: data as ProspectSearchSummaryResponse,
          },
        },
        activity: {
          ...state.activity,
          [externalId]: {
            data: [],
            isLoading: false,
          },
        },
      };
    }
    case 'Prospects/Filter/set': {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    }
    case 'Prospects/Filter/clear': {
      return {
        ...state,
        filters: {
          assignedToUserIds: [],
          leadStatusIds: [],
          tagIds: [],
          dates: null,
        },
      };
    }
    case 'Prospects/summaryDataLoading': {
      return {
        ...state,
        summary: {
          ...state.summary,
          isLoading: true,
        },
      };
    }
    case 'Prospects/summaryDataLoaded':
      return {
        ...state,
        summary: {
          ...state.summary,
          isLoading: false,
          count: action.payload.count,
        },
      };
    case 'Prospects/searchSummaryLoading': {
      const { suggestionId } = action.payload;

      return {
        ...state,
        searchSummary: {
          ...state.searchSummary,
          [suggestionId]: {
            isLoading: true,
            data: null,
          },
        },
      };
    }
    case 'Prospects/searchSummaryLoaded': {
      const { suggestionId, data } = action.payload;

      return {
        ...state,
        searchSummary: {
          ...state.searchSummary,
          [suggestionId]: {
            data: data as ProspectSearchRetrieveResponse,
            isLoading: false,
          },
        },
      };
    }
    case 'Prospects/selectProspect': {
      const { id, data } = action.payload;

      return {
        ...state,
        selectedProspect: {
          ...state.selectedProspect,
          id: id,
          data: data,
        },
      };
    }
    default:
      return state;
  }
};
