import { FeatureState, MapBox, MapBoxProps, SelectedIconMap } from '@platform/ui';
import {
  Source,
  Layer,
  MapMouseEvent,
  MapTouchEvent,
  MapboxMap,
  MapRef,
  Marker,
  Popup as ReactPopup,
  LngLatLike,
} from 'react-map-gl';
import { Feature, FeatureCollection, Point } from 'geojson';
import {
  getSpriteName,
  buildCampaignGetExpression,
  buildProspectGetExpression,
  buildProspectGetFeatureState,
} from '@platform/ui';
import { MapUI } from '@platform/ui-helpers';
import { GeoAPI } from '@platform/api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getLeadStatusIcon } from '@platform/helpers';
import { XMarkIcon } from '@heroicons/react/24/outline';

const buildProspectExpression = buildProspectGetExpression<GeoAPI.ProspectGeoJsonProperties>;
const buildCampaignExpression = buildCampaignGetExpression<GeoAPI.CampaignGeoJsonProperties>;
const emptyFeatureCollection: FeatureCollection = {
  type: 'FeatureCollection',
  features: [],
};

export type PendingProspect = {
  id: string;
  location: { lng: number; lat: number };
  line1: string;
  line2: string;
  prospectAddress: {
    address1: string;
    city: string;
    state: string;
    zip: string;
    formatted: string;
  } | null;
};
type MapSources = Extract<MapUI.Sources, MapUI.Sources.CAMPAIGNS | MapUI.Sources.PROSPECTS>;

export interface ProspectMapProps {
  zoomProp: number;
  onMapClicked: MapBoxProps['onMapClick'];
  onMarkerCreated: MapBoxProps['onMapLongPress'];
  onMapLoaded?: MapBoxProps['onMapLoaded'];
  onMapViewportChange: (mapRef: MapboxMap | MapRef, zoom: number) => void;
  onReloadMapData: () => void;
  campaignShape?: FeatureCollection | null;
  territoryPreview?: boolean;
  showCampaignLayers: boolean;
  currentBoundingBox?: any;
  enableRecenterControl?: boolean;
  onProspectSelected: (feature: Feature<Point, GeoAPI.ProspectGeoJsonProperties>) => void;
  onCampaignSelected: (feature: Feature<Point, GeoAPI.CampaignGeoJsonProperties>) => void;
  pendingProspect: PendingProspect | null;
  onPendingProspectDismiss: () => void;
  selectedProspect: Feature<Point, GeoAPI.ProspectGeoJsonProperties> | null;
  setFeatureState: (source: MapSources, id: string | number, state: FeatureState) => void;
}

const ProspectMap: React.FC<ProspectMapProps> = ({
  onMapLoaded,
  onMapViewportChange,
  onReloadMapData,
  enableRecenterControl,
  campaignShape,
  territoryPreview,
  currentBoundingBox,
  onProspectSelected,
  onCampaignSelected,
  pendingProspect,
  onPendingProspectDismiss,
  selectedProspect,
  setFeatureState,
  showCampaignLayers,
}) => {
  const [pendingProspectLocation, setPendingProspectLocation] = useState<{
    id: string;
    lng: number;
    lat: number;
  } | null>(null);
  const [previousProspectId, setPreviousProspectId] = useState<string | null>(null);

  const onProspectClicked: (e: MapMouseEvent | MapTouchEvent, map: MapboxMap) => void = useCallback(
    (e, map) => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: [MapUI.Layers.CAMPAIGNS, MapUI.Layers.PROSPECTS],
      });

      if (features?.length && features[0].layer?.id === MapUI.Layers.PROSPECTS) {
        const { id, geometry, properties } = features[0];
        onProspectSelected({
          type: 'Feature',
          id,
          geometry: geometry as Point,
          properties: properties as GeoAPI.ProspectGeoJsonProperties,
        });
      }
    },
    [onProspectSelected]
  );
  const onProspectClusterClicked: (e: MapMouseEvent | MapTouchEvent, map: MapboxMap) => void = useCallback((e, map) => {
    const features = map.queryRenderedFeatures(e.point, {
      layers: [MapUI.Layers.PROSPECT_CLUSTER],
    });

    const zoom = (features[0].properties as GeoAPI.ProspectGeoJsonProperties).expansionZoom;
    console.log(zoom, 'features');

    map.easeTo({
      center: (features[0].geometry as Point).coordinates as LngLatLike,
      zoom,
      padding: {
        top: 100,
        left: 100,
        right: 100,
        bottom: 300, // arbitrary - not sure why it doesn't center without this
      },
      duration: 200,
    });
  }, []);
  const onCampaignClicked: (e: MapMouseEvent | MapTouchEvent, map: MapboxMap) => void = useCallback(
    (e, map) => {
      console.log('onCampaignClicked', e);
      const features = map.queryRenderedFeatures(e.point, {
        layers: [MapUI.Layers.PROSPECT_CLUSTER, MapUI.Layers.CAMPAIGNS],
      });
      // Only perform the campaign click handler if the campaign layer was the top layer that was clicked
      if (features?.length && features[0].layer?.id === MapUI.Layers.CAMPAIGNS) {
        const { id, geometry, properties } = features[0];
        onCampaignSelected({
          type: 'Feature',
          id,
          geometry: geometry as Point,
          properties: properties as GeoAPI.CampaignGeoJsonProperties,
        });
      }
    },
    [onCampaignSelected]
  );

  const SelectedIcon = useMemo(
    () =>
      SelectedIconMap[`${getLeadStatusIcon(selectedProspect?.properties.icon)}-selected`] ||
      SelectedIconMap['circle-red-500-selected'],
    [selectedProspect]
  );
  const SelectedProspect = useMemo(() => {
    return !!selectedProspect ? (
      <Marker
        latitude={selectedProspect.geometry.coordinates[1]}
        longitude={selectedProspect.geometry.coordinates[0]}
        anchor="bottom"
      >
        <SelectedIcon />
      </Marker>
    ) : !!pendingProspect && !!pendingProspectLocation ? (
      <>
        <div className="bg-gray-700">
          <Marker
            latitude={pendingProspectLocation.lat}
            longitude={pendingProspectLocation.lng}
            anchor="bottom"
            draggable={!!pendingProspect.prospectAddress}
            onDragStart={() => {}}
            onDrag={() => {}}
            onDragEnd={() => {}}
            style={!pendingProspect.prospectAddress ? { display: 'none' } : {}}
          >
            <ReactPopup
              className="rounded-lg"
              latitude={pendingProspectLocation.lat}
              longitude={pendingProspectLocation.lng}
              offset={pendingProspect.prospectAddress ? 46 : 0}
              maxWidth="260px"
              anchor="bottom"
              closeButton={false}
              closeOnMove={false}
              closeOnClick={!pendingProspect.prospectAddress}
              focusAfterOpen={false}
              onClose={onPendingProspectDismiss}
            >
              <div className="p-2">
                <div className="flex items-start justify-between space-x-4">
                  <div>
                    <p className="font-medium text-base text-white leading-5">{pendingProspect.line1}</p>
                    <p className="font-normal text-sm text-gray-400">{pendingProspect.line2}</p>
                  </div>
                  <div
                    className="shrink-0 inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-700"
                    onClick={onPendingProspectDismiss}
                  >
                    <XMarkIcon className="text-white h-5 w-5" />
                  </div>
                </div>
              </div>
            </ReactPopup>
            <SelectedIcon />
          </Marker>
        </div>
      </>
    ) : (
      <></>
    );
  }, [pendingProspect, SelectedIcon, pendingProspectLocation, onPendingProspectDismiss, selectedProspect]);
  const clickHandlers = useMemo(() => {
    return {
      [MapUI.Layers.PROSPECT_CLUSTER]: onProspectClusterClicked,
      [MapUI.Layers.PROSPECTS]: onProspectClicked,
      [MapUI.Layers.CAMPAIGNS]: onCampaignClicked,
    };
  }, [onProspectClicked, onCampaignClicked, onProspectClusterClicked]);
  const Sources = useMemo(
    () => (
      <>
        <Source type="geojson" id={MapUI.Sources.PROSPECTS} promoteId="id" data={emptyFeatureCollection} />
        <Source type="geojson" id={MapUI.Sources.CAMPAIGNS} promoteId="id" data={emptyFeatureCollection} />
        <Layer
          id={MapUI.Layers.PROSPECTS}
          type="symbol"
          source={MapUI.Sources.PROSPECTS}
          filter={['==', buildProspectExpression('count'), 1]}
          paint={{
            'icon-opacity': [
              'case',
              ['boolean', buildProspectGetFeatureState('isSelected')],
              0,
              ['boolean', buildProspectExpression('new')],
              0,
              1,
            ],
          }}
          layout={{
            'icon-size': 1,
            'icon-allow-overlap': true,
            'icon-anchor': 'center',
            'icon-image': [
              'case',
              ['==', buildProspectExpression('new'), true],
              getSpriteName(`circle-red-500-selected`),
              buildProspectExpression('icon'),
            ],
          }}
        />
        <Layer
          id={MapUI.Layers.PROSPECT_CLUSTER}
          source={MapUI.Sources.PROSPECTS}
          type="circle"
          filter={['>', buildProspectExpression('count'), 1]}
          layout={{
            'circle-sort-key': buildProspectExpression('count'),
          }}
          paint={{
            'circle-color': '#EF4444',
            'circle-radius': ['interpolate', ['linear'], buildProspectExpression('count'), 0, 9, 50, 20, 100, 25],
            'circle-stroke-color': '#EF4444',
            'circle-stroke-width': ['interpolate', ['linear'], buildProspectExpression('count'), 0, 2, 50, 8, 100, 12],
            'circle-stroke-opacity': 0.5,
          }}
        />

        <Layer
          id={MapUI.Layers.PROSPECT_CLUSTER_LABEL}
          type="symbol"
          source={MapUI.Sources.PROSPECTS}
          filter={['>', buildProspectExpression('count'), 1]}
          layout={{
            'text-field': buildProspectExpression('count'),
            'text-font': ['Open Sans Bold'],
            'text-size': 14,
            'symbol-sort-key': ['*', -1, buildProspectExpression('count')],
          }}
          paint={{
            'text-color': 'white',
          }}
        />
        <Layer
          id={MapUI.Layers.CAMPAIGN_LABEL}
          source={MapUI.Sources.CAMPAIGNS}
          beforeId={MapUI.Layers.PROSPECT_CLUSTER}
          type="symbol"
          filter={['==', buildCampaignExpression('count'), 1]}
          layout={{
            'text-field': buildCampaignExpression('label'),
            'text-font': ['Inter Bold'],
            'text-size': 12,
            visibility: showCampaignLayers ? 'visible' : 'none',
          }}
          paint={{
            'text-color': 'white',
          }}
        />
        <Layer
          id={MapUI.Layers.CAMPAIGNS}
          source={MapUI.Sources.CAMPAIGNS}
          beforeId={MapUI.Layers.CAMPAIGN_LABEL}
          type="circle"
          filter={['==', buildCampaignExpression('count'), 1]}
          layout={{ visibility: showCampaignLayers ? 'visible' : 'none' }}
          paint={{
            'circle-color': buildCampaignExpression('color'),
            'circle-radius': 15,
            'circle-stroke-color': '#FFFFFF',
            'circle-stroke-width': 2,
          }}
        />
        <Source type="geojson" id={MapUI.Sources.CAMPAIGN_SHAPES} data={campaignShape ?? emptyFeatureCollection}>
          <Layer
            id={MapUI.Layers.CAMPAIGN_SHAPE_AREA}
            source={MapUI.Sources.CAMPAIGN_SHAPES}
            beforeId={MapUI.Layers.CAMPAIGNS}
            type="fill"
            layout={{ visibility: territoryPreview && showCampaignLayers ? 'visible' : 'none' }}
            paint={{
              'fill-color': '#3388FF',
              'fill-opacity': 0.25,
            }}
          />
          <Layer
            id={MapUI.Layers.CAMPAIGN_SHAPE_BORDER}
            source={MapUI.Sources.CAMPAIGN_SHAPES}
            beforeId={MapUI.Layers.CAMPAIGNS}
            type="line"
            layout={{ visibility: !!campaignShape && showCampaignLayers ? 'visible' : 'none' }}
            paint={{
              'line-color': '#3388FF',
              'line-width': 3,
              ...(!!territoryPreview ? {} : { 'line-dasharray': [3, 3] }),
            }}
          />
        </Source>
      </>
    ),
    [campaignShape, territoryPreview, showCampaignLayers]
  );

  useEffect(() => {
    if (previousProspectId && previousProspectId !== selectedProspect?.properties.id) {
      setFeatureState(MapUI.Sources.PROSPECTS, previousProspectId, { isSelected: false });
    }
    if (selectedProspect?.properties.id) {
      setFeatureState(MapUI.Sources.PROSPECTS, selectedProspect.properties.id, { isSelected: true });
    }
    setPreviousProspectId((selectedProspect?.properties.id as string) ?? null);
  }, [selectedProspect?.properties?.id, previousProspectId, setFeatureState]);

  useEffect(() => {
    if (!pendingProspect) {
      setPendingProspectLocation(null);
    } else if (!pendingProspectLocation || pendingProspectLocation.id !== pendingProspect.id) {
      setPendingProspectLocation({ id: pendingProspect.id, ...pendingProspect.location });
    }
  }, [pendingProspect, pendingProspectLocation]);

  return (
    <>
      <MapBox
        locationPermission="granted"
        isPortrait={false}
        zoomProp={4}
        boundingBox={currentBoundingBox}
        clickHandlers={clickHandlers}
        onMapMove={onMapViewportChange}
        onReloadMap={onReloadMapData}
        isVisible={true}
        onMapClick={(e) => console.log('onMapClick', e)}
        enableRecenterControl={true}
        onMapLoaded={onMapLoaded}
      >
        {Sources}
        {SelectedProspect}
      </MapBox>
    </>
  );
};

export default ProspectMap;
