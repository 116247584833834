import DropdownMenu from './DropdownMenu';

interface StatusFilterProps {
  selected: { title: string; description: string; value: string };
  onSelect: (filter: { title: string; description: string; value: string }) => void;
  options: { title: string; description: string; value: string }[];
}

export const StatusFilterUI = ({ selected, onSelect, options }: StatusFilterProps) => {
  return (
    <div
      className="flex items-center justify-between mt-1 w-60 py-2 px-3 bg-white cursor-pointer"
    >
      <div>
        <div className="text-sm font-medium">{selected.title}</div>
      </div>
      <DropdownMenu 
        onSelect={onSelect}
        itemOptions={options} 
      />
    </div>
  );
};