"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.RouteDefinitions = exports.CheckoutAPI = void 0;
var app_config_1 = require("@platform/app-config");
var helpers_1 = require("@platform/helpers");
var CheckoutAPI;
(function (CheckoutAPI) {
    CheckoutAPI["GET /v1/checkout"] = "GET /v1/checkout";
    CheckoutAPI["GET /v1/checkout-success"] = "GET /v1/checkout-success";
    CheckoutAPI["GET /v1/checkout-cancel"] = "GET /v1/checkout-cancel";
})(CheckoutAPI = exports.CheckoutAPI || (exports.CheckoutAPI = {}));
exports.RouteDefinitions = __assign(__assign(__assign({}, (0, helpers_1.unauthenticatedRoute)('GET /v1/checkout')), (0, helpers_1.unauthenticatedRoute)('GET /v1/checkout-success')), (0, helpers_1.unauthenticatedRoute)('GET /v1/checkout-cancel'));
