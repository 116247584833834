import React from 'react'
import { Drawer, ModalHeaderToolbar } from '@platform/ui'

interface CampaignTitleProps {
  onBack: () => void
  onSave: () => void
  onFormInput: (e: React.ChangeEvent<HTMLInputElement>) => void
  campaignTitle: string
  isDisabled?: boolean
  isLoading?: boolean
}

const CampaignTitle = ({ onBack, onSave, onFormInput, campaignTitle, isDisabled, isLoading }: CampaignTitleProps) => {
  return (
    <Drawer isOpen={true} onClose={onBack}>
      <ModalHeaderToolbar
        onBack={onBack}
        onSave={onSave}
        title='Rename Campaign'
        isLoading={isLoading}
        isDisabled={isDisabled}
      />
      <div className='flex flex-col grow w-full max-w-3xl px-6 pb-8 pt-2 mx-auto'>
        <input
          onInput={onFormInput}
          value={campaignTitle}
          placeholder=''
          type='text'
          className='flex flex-col rounded border border-gray-200 bg-gray-50 h-12 px-7 text-center text-lg font-semibold leading-5 text-gray-900 placeholder:text-gray-900'
        />
        <p className='text-sm font-normal text-gray-500 text-center mt-3'>
          Enter a title that will help you remember this area, like the name of the neighborhood.
        </p>
      </div>
    </Drawer>
  )
}

export default CampaignTitle
