import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export type MapMode = "none" | "territory-preview";

export type QueryParams = {
  mode: MapMode;
  campaignId?: string;
};

export const useCampaignNavigation = () => {
  const navigate = useNavigate();
  const locationHistory = useLocation();

  const initiateMode = useCallback(
    (q: QueryParams | null) => navigate(buildCampaignPath(q, locationHistory)),
    [navigate, locationHistory]
  );

  return { initiateMode };
};

export const buildCampaignPath = (q: QueryParams | null, locationHistory: ReturnType<typeof useLocation>) => {
  const portalRoute = locationHistory.pathname;
  let queryParams: Record<string, string> = {};

  if (!q) {
    queryParams = { mode: "none", campaignId: "0" };
  } else if (q.campaignId) {
    queryParams = { mode: q.mode, campaignId: `${q.campaignId}` };
  } else {
    queryParams = { mode: q.mode };
  }

  const searchParams = new URLSearchParams(queryParams).toString();
  return `${portalRoute}?${searchParams}`;
};

function isTerritoryPreview(x: string | null): x is MapMode {
  const mode: MapMode = 'territory-preview';
  return x === mode;
}

function isNone(x: string | null): x is MapMode {
  const mode: MapMode = 'none';
  return x === mode;
}

function parseMode(x: string | null): MapMode | null {
  if (x === null) {
    return null;
  }
  if (isTerritoryPreview(x) || isNone(x)) {
    return x;
  } else {
    return null;
  }
}

function parseCampaignId(x: string | null): QueryParams['campaignId'] | null {
  return x;
}

export const parseQueryParams = (q: URLSearchParams): QueryParams | null => {
  const [modeKey, campaignIdKey]: (keyof QueryParams)[] = ['mode', 'campaignId'];
  const [mode, campaignId]: [QueryParams['mode'] | null, QueryParams['campaignId'] | null] = [
    parseMode(q.get(modeKey)),
    parseCampaignId(q.get(campaignIdKey)),
  ];

  if (mode === null || campaignId === null) {
    console.error(`Invalid query param values: campaignId ${campaignId} and mode ${mode}`);
    return null;
  }

  if (mode === 'none') {
    q.delete(campaignIdKey);
    return {
      mode: 'none',
    };
  }

  return {
    mode,
    campaignId,
  };
};