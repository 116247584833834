import { AppReducer } from '../types/reducer-types';
import { initialState } from '../state';
import { CampaignsState, initialCampaignState } from '../state/campaigns-state';

export const campaignsReducer: AppReducer<'Campaigns'> = (state = initialState.Campaigns, action): typeof state => {
  switch (action.type) {
    case 'Campaigns/loaded': {
      const spliced = [...state.campaignList];
      spliced.splice(action.payload.startingIndex);
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        totalRecords: action.payload.totalCount,
        campaignList: [...spliced, ...action.payload.campaigns.map(({ externalTrackingId }) => externalTrackingId)],
        campaigns: action.payload.campaigns.reduce<CampaignsState['campaigns']>(
          (acc, campaign) => ({
            ...acc,
            [campaign.externalTrackingId]: campaign,
          }),
          { ...state.campaigns }
        ),
      };
    }
    case 'Campaigns/updateCampaignLeadStatus': {
      const campaignId = action.payload.campaignId;
      const progress = { ...state.campaigns[action.payload.campaignId].leadStatusSummary };

      if (action.payload.previousLeadStatus !== null && progress[action.payload.previousLeadStatus]) {
        if (progress[action.payload.previousLeadStatus].count === 1) {
          delete progress[action.payload.previousLeadStatus];
        } else {
          progress[action.payload.previousLeadStatus].count--;
        }
      }

      (progress[action.payload.newLeadStatus.id] ??= { count: 0, value: action.payload.newLeadStatus.value }).count++;

      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          [campaignId]: {
            ...state.campaigns[campaignId],
            leadStatusSummary: {
              ...progress,
            },
          },
        },
      };
    }
    case 'Campaigns/loading':
      return {
        ...state,
        isLoading: true,
      };
    case 'Campaigns/clear':
      return {
        ...initialCampaignState,
      };
    case 'Campaigns/update':
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          [action.payload.externalId]: {
            ...state.campaigns[action.payload.externalId],
            ...action.payload.update,
          },
        },
      };
    case 'Campaigns/set':
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          [action.payload.externalTrackingId]: action.payload,
        },
      };
    case 'Campaigns/add':
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          [action.payload.externalTrackingId]: {
            ...action.payload,
          },
        },
        campaignList: [action.payload.externalTrackingId, ...state.campaignList],
        totalRecords: state.totalRecords + 1,
      };
    case 'Campaigns/watch':
      return {
        ...state,
        watchCampaigns: [...state.watchCampaigns, action.payload],
      };
    case 'Campaigns/unwatch':
      return {
        ...state,
        watchCampaigns: state.watchCampaigns.filter((c) => c !== action.payload),
      };
    case 'Campaigns/removeProspect': {
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          [action.payload.campaignId]: {
            ...state.campaigns[action.payload.campaignId],
            targets: (state.campaigns[action.payload.campaignId]?.targets ?? 1) - 1,
            leadStatusSummary: {
              ...state.campaigns[action.payload.campaignId]?.leadStatusSummary,
              ...(action.payload.leadStatusId
                ? {
                    [action.payload.leadStatusId]: {
                      ...state.campaigns[action.payload.campaignId]?.leadStatusSummary[action.payload.leadStatusId],
                      count:
                        state.campaigns[action.payload.campaignId]?.leadStatusSummary[action.payload.leadStatusId]
                          .count - 1,
                    },
                  }
                : {}),
            },
          },
        },
      };
    }
    default:
      return state;
  }
};
