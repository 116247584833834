"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.ALL_SUBSCRIPTION_PLANS = exports.SUBSCRIPTION_PLANS = exports.LEGACY_SUBSCRIPTION_PLANS = exports.STARTER_FREE_TRIAL_PROSPECTS = exports.DEFAULT_PROSPECT_LIST_SIZE = exports.DEFAULT_HWN_PRICE = exports.DEFAULT_POSTCARD_PRICE = void 0;
exports.DEFAULT_POSTCARD_PRICE = 119;
exports.DEFAULT_HWN_PRICE = 419;
exports.DEFAULT_PROSPECT_LIST_SIZE = 20;
exports.STARTER_FREE_TRIAL_PROSPECTS = 100;
exports.LEGACY_SUBSCRIPTION_PLANS = {
    STARTER_PER_USER: {
        platformFee: 4000,
        prospectsExtra: 10,
        prospectsIncluded: 500,
        freeTrialPeriod: 14,
        usersIncluded: 1,
        perUserExtra: 4000,
        features: [],
        marketingCredits: 0,
        marketingPrebuyMinimum: 300000,
        marketingOnboarding: 50000,
        postcardPrice: 199,
        hwnPrice: 599,
        programsExtra: 4000,
        locationsIncluded: 1,
        locationsExtra: null,
        programsIncluded: 0,
        crmAutomationSetupExtra: 25000,
        priceIds: function (env) {
            return env === 'production'
                ? [
                    { id: 'price_1N86LpI28MnHvcBkwvxBPI5Q', interval: 'monthly', "default": true },
                    { id: 'price_1OLBjtI28MnHvcBk91Bk2NjL', interval: 'annual' },
                    { id: 'price_1OCOrJI28MnHvcBkhFc3vqOC', interval: 'annual' },
                ]
                : [{ id: 'price_1N83fnI28MnHvcBkREy6Z34O', interval: 'monthly', "default": true }];
        }
    },
    STARTER: {
        platformFee: 20000,
        prospectsExtra: 10,
        prospectsIncluded: 500,
        freeTrialPeriod: 14,
        usersIncluded: 'unlimited',
        features: ['zapier', 'campaigns'],
        marketingCredits: 0,
        marketingOnboarding: null,
        postcardPrice: 100,
        hwnPrice: 400,
        perUserExtra: 0,
        programsExtra: 5000,
        locationsIncluded: 1,
        locationsExtra: null,
        programsIncluded: 0,
        crmAutomationSetupExtra: 25000,
        productId: function (env) { return (env === 'production' ? 'prod_MjX1KJKZJ0dGwf' : 'prod_MaeCsHV1OgwMo9'); }
    },
    PRO_SCOUT_MARKETING: {
        platformFee: 40000,
        prospectsExtra: 5,
        prospectsIncluded: 500,
        freeTrialPeriod: 0,
        usersIncluded: 30,
        perUserExtra: 1000,
        marketingCredits: 50000,
        marketingOnboarding: null,
        postcardPrice: 89,
        hwnPrice: 349,
        programsIncluded: 3,
        programsExtra: 2000,
        locationsIncluded: 1,
        locationsExtra: 7500,
        crmAutomationSetupExtra: 25000,
        features: ['zapier', 'campaigns'],
        productId: function (env) { return (env === 'production' ? 'prod_Ns23ots4xNKGTJ' : 'prod_NtrPMG8btJ8Ob0'); }
    },
    ENTERPRISE_DIRECT_MAIL_CUSTOM: {
        platformFee: 62500,
        prospectsIncluded: 'unlimited',
        freeTrialPeriod: 7,
        usersIncluded: 40,
        perUserExtra: null,
        marketingOnboarding: 150000,
        postcardPrice: 98,
        hwnPrice: 419,
        programsIncluded: 0,
        programsExtra: null,
        locationsIncluded: 3,
        locationsExtra: 5000,
        crmAutomationSetupExtra: null,
        features: ['zapier', 'homeowner_names', 'demographics', 'campaigns'],
        productId: function (env) { return (env === 'production' ? 'prod_PL5FuZv3MZInOo' : 'prod_PQLVPoL7eWtQOe'); }
    }
};
exports.SUBSCRIPTION_PLANS = {
    SOLO_SCOUT: {
        platformFee: 5000,
        freeTrialPeriod: 7,
        usersIncluded: 1,
        prospectsIncluded: 'unlimited',
        perUserExtra: null,
        marketingOnboarding: null,
        postcardPrice: null,
        hwnPrice: null,
        programsIncluded: null,
        programsExtra: null,
        locationsIncluded: 1,
        locationsExtra: null,
        teamOnboardingExtra: 25000,
        crmAutomationSetupExtra: 25000,
        features: ['zapier', 'homeowner_names'],
        productId: function (env) { return (env === 'production' ? 'prod_R4Yj7W8bQF2voR' : 'prod_R4z5xQ79Vyb6Cw'); }
    },
    STARTER_SCOUT: {
        platformFee: 12500,
        freeTrialPeriod: 7,
        usersIncluded: 3,
        prospectsIncluded: 'unlimited',
        perUserExtra: null,
        marketingOnboarding: null,
        postcardPrice: null,
        hwnPrice: null,
        programsIncluded: null,
        programsExtra: null,
        locationsIncluded: 1,
        locationsExtra: null,
        teamOnboardingExtra: 25000,
        crmAutomationSetupExtra: 25000,
        features: ['zapier', 'homeowner_names'],
        productId: function (env) { return (env === 'production' ? 'prod_NtuAEkWASJJHFa' : 'prod_NtrOvQnVgXTv6X'); },
        priceIds: function (env) {
            return env === 'production'
                ? [{ interval: 'monthly', id: 'price_1QD3lJI28MnHvcBkkYnKsBTf', "default": true }]
                : [{ interval: 'monthly', id: 'price_1ObRnyI28MnHvcBk8OU3cn4A', "default": true }];
        }
    },
    PRO_SCOUT: {
        platformFee: 35000,
        prospectsIncluded: 'unlimited',
        freeTrialPeriod: 7,
        usersIncluded: 20,
        perUserExtra: null,
        marketingOnboarding: 150000,
        postcardPrice: 98,
        hwnPrice: 419,
        programsExtra: null,
        locationsIncluded: 1,
        locationsExtra: 7500,
        programsIncluded: 0,
        crmAutomationSetupExtra: 25000,
        features: ['zapier', 'homeowner_names', 'demographics', 'campaigns'],
        productId: function (env) { return (env === 'production' ? 'prod_Nsm2N5GkNou1Ul' : 'prod_NsmJGQ497aaz5z'); },
        priceIds: function (env) {
            return env === 'production'
                ? [{ interval: 'monthly', id: 'price_1QD3k6I28MnHvcBkEx0RwVVX', "default": true }]
                : [{ interval: 'monthly', id: 'price_1N70kOI28MnHvcBkd8edv7RB', "default": true }];
        }
    },
    LP_PRO_SCOUT: {
        platformFee: 35000,
        prospectsIncluded: 'unlimited',
        freeTrialPeriod: 14,
        usersIncluded: 20,
        perUserExtra: null,
        marketingOnboarding: 150000,
        postcardPrice: 98,
        hwnPrice: 419,
        programsExtra: null,
        locationsIncluded: 1,
        locationsExtra: 7500,
        programsIncluded: 0,
        crmAutomationSetupExtra: 25000,
        features: ['zapier', 'homeowner_names', 'demographics', 'campaigns'],
        productId: function (env) { return (env === 'production' ? 'prod_QZRc7hdKPDc7YM' : 'prod_NsmJGQ497aaz5z'); },
        priceIds: function (env) {
            return env === 'production'
                ? [{ interval: 'annual', id: 'price_1PiIivI28MnHvcBkSKk3W7Zc', "default": true }]
                : [{ interval: 'monthly', id: 'price_1N70kOI28MnHvcBkd8edv7RB', "default": true }];
        }
    },
    ENTERPRISE: {
        platformFee: 62500,
        prospectsIncluded: 'unlimited',
        freeTrialPeriod: 7,
        usersIncluded: 40,
        perUserExtra: null,
        marketingOnboarding: 150000,
        postcardPrice: 98,
        hwnPrice: 419,
        programsIncluded: 0,
        programsExtra: null,
        locationsIncluded: 3,
        locationsExtra: 5000,
        crmAutomationSetupExtra: null,
        features: ['zapier', 'homeowner_names', 'demographics', 'campaigns'],
        productId: function (env) { return (env === 'production' ? 'prod_OSgsNZiX9IBZat' : 'prod_OSh6s305AOrvuP'); },
        priceIds: function (env) {
            return env === 'production'
                ? [{ interval: 'monthly', id: 'price_1QD3m8I28MnHvcBkmoB2OqTq', "default": true }]
                : [{ interval: 'monthly', id: 'price_1NflhzI28MnHvcBkNSRr5Nq5', "default": true }];
        }
    },
    PARTNER_ACCESS: {
        platformFee: 0,
        prospectsIncluded: 'unlimited',
        freeTrialPeriod: 0,
        usersIncluded: 'unlimited',
        perUserExtra: null,
        marketingOnboarding: null,
        postcardPrice: null,
        hwnPrice: null,
        programsIncluded: 0,
        programsExtra: null,
        locationsIncluded: 3,
        locationsExtra: null,
        crmAutomationSetupExtra: null,
        features: ['zapier', 'homeowner_names', 'demographics', 'campaigns'],
        productId: function (env) { return (env === 'production' ? 'prod_PR22Kdl6myIjHC' : 'prod_RgMcUumZmiwWgU'); }
    }
};
exports.ALL_SUBSCRIPTION_PLANS = __assign(__assign({}, exports.SUBSCRIPTION_PLANS), exports.LEGACY_SUBSCRIPTION_PLANS);
