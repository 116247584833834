import { useCallback, useEffect, useMemo, useState } from 'react'
import { pull } from 'lodash'
import { Avatar, CheckboxItemProps, CheckboxList, Drawer, ModalHeaderToolbar } from '@platform/ui'

type UserCheckboxOption = {
  id: number
  label: string
}

interface UserFilterModalProps {
  show: boolean
  selected: number[]
  options: UserCheckboxOption[]
  onExit: () => void
  onSubmit: (ids: number[]) => void
  title: string
  myId: number
}

export const UsersModal = ({ show, selected, options, onExit, onSubmit, title, myId }: UserFilterModalProps) => {
  const [newUsersIds, setNewUserIds] = useState<number[]>([])

  useEffect(() => {
    setNewUserIds(selected)
  }, [selected, show])

  const onUserSelected = useCallback(({ id }: UserCheckboxOption, selected: boolean) => {
    setNewUserIds(ids => (selected ? Array.from(new Set([...ids, id])) : pull([...ids], id)))
  }, [])

  const handleSubmit = useCallback(() => onSubmit(newUsersIds), [newUsersIds, onSubmit])

  const UserListItem = useCallback(
    (p: CheckboxItemProps<UserCheckboxOption>) => {
      return (
        <div className='flex space-x-3 items-center'>
          <Avatar size='medium' avatar={{ name: p.option.label }} />
          <p className='text-base font-medium text-gray-900'>
            {myId === p.option.id ? `${p.option.label} (Me)` : p.option.label}
          </p>
        </div>
      )
    },
    [myId]
  )

  const { primaryActionText, primaryAction } = useMemo(() => {
    if (newUsersIds.length) {
      return {
        primaryActionText: 'Deselect All',
        primaryAction: () => setNewUserIds([])
      }
    }
    return { primaryActionText: undefined, primaryAction: undefined }
  }, [newUsersIds.length])

  return (
    <>
      <Drawer isOpen={show} onClose={onExit}>
        <ModalHeaderToolbar
          onBack={handleSubmit}
          onSave={primaryAction}
          title={title}
          hasShadow
          saveButtonText={primaryActionText}
        />
        <div className='p-4 grid gap-2'>
          <CheckboxList
            options={options}
            selectedOptions={newUsersIds}
            onSelected={onUserSelected}
            CheckboxListItemLayout={ItemLayout}
            CheckboxItem={UserListItem}
          />
        </div>
      </Drawer>
    </>
  )
}

const ItemLayout = ({
  onToggle,
  Content,
  ...contentProps
}: {
  onToggle: (checked: boolean) => void
  Content: React.FC<any>
  value: boolean
}) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onToggle(e.target.checked)
    },
    [onToggle]
  )

  return (
    <div
      className={`flex items-center justify-between px-4 py-2 border rounded-md ${
        contentProps.value ? 'bg-blue-50 border-blue-500' : 'border-gray-300'
      }`}
    >
      <div className='flex items-center space-x-3'>
        <input
          type='checkbox'
          className='h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500'
          checked={contentProps.value}
          onChange={handleChange}
        />
        <Content {...contentProps} />
      </div>
    </div>
  )
}
