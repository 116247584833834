import { DateFilterWithDescription, CustomDateFilterWithDescription } from '@platform/helpers'
import { Divider, Drawer, ModalHeaderToolbar } from '@platform/ui'
import classNames from 'classnames'
import moment from 'moment'
import { useCallback, useMemo, useState } from 'react'
import { Hooks, Utils } from '@platform/ui-helpers'
import ReactDatePicker from 'react-datepicker'
import { useSelector } from 'react-redux'
import { Selectors } from '../../../../redux/selectors'

// Types
interface DateFilterModalProps {
  show: boolean
  onExit: () => void
  onReset: () => void
  onSubmit: (filter: CustomDateFilterWithDescription | null) => void
  filter: DateFilterWithDescription | null
  isTablet: boolean
  isPortrait: boolean
}

type DateRange = Utils.DateRange
type BeforeAfter = Utils.BeforeAfter

type CalendarProps = {
  type: BeforeAfter
  title: string
  onChange: (value: Date | null) => void
  range: { before: string | null; after: string | null }
  minDate?: Date | null
  maxDate: Date
}

// Constants
const today = moment().format('M/D/YYYY')
const defaultDateRange: DateRange = { after: null, before: today, title: 'All time' }

const Calendar = (props: CalendarProps) => {
  const value = useMemo(() => {
    const value = props.range[props.type]
    return value ? new Date(value) : null
  }, [props])

  return (
    <div className='absolute top-full right-1 z-50 bg-white shadow-lg border rounded mt-2'>
      <ReactDatePicker
        id={props.type}
        title={props.title}
        name={props.title}
        selected={value}
        onChange={props.onChange}
        minDate={props.minDate ? new Date(props.minDate) : undefined}
        maxDate={undefined}
        placeholderText={props.type === 'after' ? 'No start date' : undefined}
        inline
      />
    </div>
  )
}

const buildRangeFromCalendarEvent = (
  range: Utils.DateRange,
  type: Utils.BeforeAfter,
  value: Date | null,
  defaultDateRange: Utils.DateRange
): Utils.DateRange => {
  const newRange = {
    ...range,
    [type]: moment(value).startOf('day').format('M/D/YYYY')
  }

  return {
    ...newRange,
    title: Utils.buildCustomDateTitle(newRange, defaultDateRange)
  }
}

export const DateFilterModal = ({ show, onExit, onSubmit, filter, onReset }: DateFilterModalProps) => {
  const { createdAt: companyCreatedAt } = useSelector(Selectors.company)

  const customDateProps: Hooks.UseCustomDateModalProps = useMemo(
    () => ({
      companyCreatedAt: companyCreatedAt ? new Date(companyCreatedAt) : null,
      isVisible: show,
      filter: filter?.id === 'custom-range' ? filter : null,
      onSubmit,
      onExit,
      defaultDateRange
    }),
    [companyCreatedAt, filter, onExit, onSubmit, show]
  )

  const { handleSubmit, newDateRange, setNewDateRange } = Hooks.useCustomDateModal(customDateProps)

  const handleSetDate = useCallback(
    (type: BeforeAfter, value: Date | null) => {
      setNewDateRange(r => buildRangeFromCalendarEvent(r, type, value, defaultDateRange))
    },
    [setNewDateRange]
  )

  return (
    <Drawer isOpen={show} onClose={onExit}>
      <ModalHeaderToolbar
        onBack={handleSubmit}
        onSave={onReset}
        title='Status Last Updated'
        subtitle='Date Range'
        saveButtonText={<div className={classNames({ 'text-gray-500': true })}>Reset</div>}
      />
      <div>
        <ul>
          <li>
            <DateRow
              minDate={companyCreatedAt}
              onChange={handleSetDate}
              range={newDateRange}
              type='after'
              title='Start Date'
            />
            <Divider />
            <DateRow
              minDate={companyCreatedAt}
              onChange={handleSetDate}
              range={newDateRange}
              type='before'
              title='End Date'
            />
          </li>
        </ul>
      </div>
    </Drawer>
  )
}

const DateRow = (props: {
  type: BeforeAfter
  title: string
  minDate?: Date | null
  range: Utils.DateRange
  onChange: (type: BeforeAfter, date: Date | null) => void
}) => {
  const closeCalendar = useCallback(() => {
    setIsCalendarOpen(false)
  }, [])

  const onChange = useCallback(
    (value: Date | null) => {
      props.onChange(props.type, value)
      setTimeout(closeCalendar, 0)
    },
    [props, closeCalendar]
  )

  const value =
    props.type === 'after' && !props.range.after
      ? 'After Account Creation'
      : props.range[props.type] === today
      ? 'Today'
      : props.range[props.type]

  const minDate = useMemo(
    () => (props.type === 'before' && props.range.after ? new Date(props.range.after) : props.minDate),
    [props.minDate, props.range.after, props.type]
  )
  const maxDate = useMemo(
    () => (props.type === 'after' && props.range.before ? new Date(props.range.before) : new Date()),
    [props.range.before, props.type]
  )
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const handleOpenCalendar = useCallback(() => setIsCalendarOpen(true), [])

  return (
    <div className='relative flex items-center justify-between px-4 py-2'>
      <div className='flex flex-col justify-start'>
        <div className='text-gray-900 font-medium'>{props.title}</div>
        <div className='text-gray-500 text-sm font-normal'>{!value || value === today ? 'Today' : value}</div>
      </div>
      <div className='flex flex-col justify-start'>
        <div
          onClick={handleOpenCalendar}
          className='font-semibold text-sm text-blue-500 update-filter-date cursor-pointer'
        >
          Update
          {isCalendarOpen && (
            <Calendar
              type={props.type}
              range={props.range}
              title={props.title}
              onChange={onChange}
              minDate={minDate}
              maxDate={maxDate}
            />
          )}
        </div>
      </div>
    </div>
  )
}
