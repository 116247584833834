import { useAuth0 } from '@auth0/auth0-react'
import { CalendarIcon, ChevronRightIcon, TagIcon, UserIcon } from '@heroicons/react/24/outline'
import { GetTeamResponse, TeamUserResponse } from '@platform/api/dist/portal-api/user-api.types'
import {
  DateFilterDescription,
  DateOptionDescriptions,
  ProspectDateFilterOption,
  ProspectFilter
} from '@platform/helpers'
import {
  DateQuickSelect,
  Divider,
  Drawer,
  FlameIcon,
  LeadStatusItem,
  LeadStatusMultiSelect,
  ModalHeaderToolbar,
  SectionHeader,
  Tag,
  TagsGroup,
  UserGroup
} from '@platform/ui'
import { Utils } from '@platform/ui-helpers'
import classNames from 'classnames'
import { difference, pull, sortBy } from 'lodash'
import objectHash from 'object-hash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDebounce } from 'use-debounce'
import { Selectors } from '../../../../redux/selectors'
import { useTypedDispatch } from '../../../../redux/state'
import { getProspectSummary, loadAllTags, loadLeadStatuses } from '../../../../redux/thunks'
import { DateFilterModal } from './date-modal'
import { StatusFilterUI } from './status-filter-options'
import { TagsModal } from './tags-modal'
import { UsersModal } from './users-modal'

interface FiltersBottomSheetProps {
  onApplyFilters: (filters: ProspectFilter) => void
  onExit: () => void
  filters: ProspectFilter | null
  show: boolean
}

const ProspectDateOptionsList = [
  DateOptionDescriptions.today,
  DateOptionDescriptions.yesterday,
  DateOptionDescriptions['last-week'],
  DateOptionDescriptions['wtd'],
  DateOptionDescriptions['mtd'],
  DateOptionDescriptions['ytd'],
  DateOptionDescriptions.all
] as DateFilterDescription<ProspectDateFilterOption>[]

const AppointmentDateOptionsList = [
  DateOptionDescriptions.today,
  DateOptionDescriptions.tomorrow,
  DateOptionDescriptions['wtd'],
  DateOptionDescriptions['nwtd'],
  DateOptionDescriptions.all,
] as DateFilterDescription<ProspectDateFilterOption>[];

const defaultDateFilter = DateOptionDescriptions.all

const defaultFilters: Required<ProspectFilter> = {
  assignedToUserIds: [],
  leadStatusIds: [],
  tagIds: [],
  dates: null
}

const FiltersBottomSheet: React.FC<FiltersBottomSheetProps> = ({ show, filters, onExit, onApplyFilters }) => {
  const dispatch = useTypedDispatch()
  const auth0 = useAuth0()

  const leadStatuses = useSelector(Selectors.leadStatuses)
  const { isLoading: isTagsLoading, isLoaded: isTagsLoaded } = useSelector(Selectors.getTagsState)
  const { isLoading: isTeamLoading, isLoaded: isTeamLoaded } = useSelector(Selectors.team)
  const { isLoading: allProspectCountFetching, count: allProspectCount } = useSelector(Selectors.prospectSummary)
  const { isLoading: filteredProspectCountFetching, count: filteredProspectCount } = useSelector(
    Selectors.prospectSummary
  )
  const { user: loggedInUser } = useSelector(Selectors.user)
  const teamsList: GetTeamResponse = useSelector(Selectors.team)

  const statusOptions = [
    {
      title: 'Lead Status Updated',
      description: 'Filter by date of a status update.',
      value: 'lead_status_updated',
    },
    {
      title: 'Appointment Date',
      description: 'Filter by date of the appointment (if any).',
      value: 'appointment_date',
    },
    {
      title: 'Prospect Created',
      description: 'Filter by the date the prospect was created.',
      value: 'prospect_created',
    },
  ];

  const [newFilters, setNewFilters] = useState(defaultFilters)
  const [showFilters, setShowFilters] = useState(false)
  const [showSecondaryModal, setShowSecondaryModal] = useState<'tags' | 'users' | 'dates' | null>(null)
  const [totalCount, setTotalCount] = useState(0);
  const [selectedDateRange, setSelectedDateRange] = useState(statusOptions[0]);

  const [debouncedFilters] = useDebounce(newFilters, 500, { leading: true })

  const tags = useSelector(Selectors.getTagsWithKeys)
  const tagsList = useSelector(Selectors.getTagsExternalIds)

  const updateLeadStatusFilter = useCallback(
    (values: (number | null)[]) => {
      setNewFilters(s => ({
        ...s,
        leadStatusIds: values
      }))
    },
    [setNewFilters]
  )
  const setDateFilter = useCallback((value: ProspectFilter['dates']) => {
    return setNewFilters(({ dates: _, ...s }) => {
      if (!value || value.id === 'all') {
        return { ...s, dates: null };
      }
      return {
        ...s,
        dates: {
          ...value,
          type: selectedDateRange.value,
        },
      };
    });
  }, [selectedDateRange, setNewFilters])
  const closeSecondaryModal = useCallback(() => setShowSecondaryModal(null), [])
  const handleDateModalSubmit = useCallback(
    (value: ProspectFilter['dates']) => {
      setDateFilter(value)
      closeSecondaryModal()
    },
    [closeSecondaryModal, setDateFilter]
  )
  const handleDateModalReset = useCallback(
    () => {
      setDateFilter(null)
      closeSecondaryModal()
    },
    [closeSecondaryModal, setDateFilter]
  )
  const updateTagFilter = useCallback(
    (value: string) =>
      setNewFilters(s => ({
        ...s,
        tagIds: s.tagIds.includes(value) ? pull([...s.tagIds], value) : [...(s.tagIds ?? []), value]
      })),
    []
  )
  const openSecondaryModal = useCallback((type: 'tags' | 'users' | 'dates') => () => setShowSecondaryModal(type), [])
  const onClearAllLeadStatuses = useCallback(
    () =>
      setNewFilters(s => {
        return {
          ...s,
          leadStatusIds: []
        }
      }),
    []
  )
  const updateAssignedUserFilter = useCallback((value: number) => {
    setNewFilters(s => ({
      ...s,
      assignedToUserIds: s.assignedToUserIds.includes(value)
        ? pull([...s.assignedToUserIds], value)
        : [...(s.assignedToUserIds ?? []), value]
    }))
  }, [])
  const setAssignedUserFilter = useCallback(
    (values: number[]) =>
      setNewFilters(s => ({
        ...s,
        assignedToUserIds: values
      })),
    []
  )
  const handleAssignedUserModalSubmit = useCallback(
    (values: number[]) => {
      setAssignedUserFilter(values)
      closeSecondaryModal()
    },
    [closeSecondaryModal, setAssignedUserFilter]
  )
  const setTagFilter = useCallback(
    (values: string[]) =>
      setNewFilters(s => ({
        ...s,
        tagIds: values
      })),
    []
  )
  const handleTagsModalSubmit = useCallback(
    (values: string[]) => {
      setTagFilter(values)
      closeSecondaryModal()
    },
    [closeSecondaryModal, setTagFilter]
  )
  const handleClearAllFilters = useCallback(() => {
    setNewFilters(defaultFilters)
  }, [])
  const handleCloseFilters = useCallback(() => {
    setShowFilters(false)
  }, [])
  const handleApplyFilter = useCallback(() => {
    if (objectHash(filters ?? defaultFilters) !== objectHash(newFilters)) {
      onApplyFilters(newFilters)
    }
    onExit()
  }, [filters, newFilters, onApplyFilters, onExit])

  const suggestedTags = useMemo(() => {
    const idList = newFilters.tagIds.length ? difference(tagsList, newFilters.tagIds) : tagsList
    return idList
      .slice(0, 5)
      .map(id => ({
        ...tags[id],
        onClick: () => updateTagFilter(id)
      }))
      .filter(t => !t.hiddenAt)
  }, [tagsList, newFilters.tagIds, tags, updateTagFilter])
  const tagOptions = useMemo(
    () => tagsList.map(id => ({ label: tags[id].value, value: tags[id].externalId })),
    [tagsList, tags]
  )
  const tagsSuggestionState = useMemo(() => {
    return {
      show: !filters?.tagIds.length || !newFilters.tagIds.length,
      action:
        tagOptions.length > suggestedTags.length
          ? {
              text: 'Browse all tags',
              onClick: openSecondaryModal('tags'),
              icon: null
            }
          : null,
      isLoading: isTagsLoading,
      options: suggestedTags
    }
  }, [filters, newFilters.tagIds.length, suggestedTags, openSecondaryModal, tagOptions.length, isTagsLoading])
  const selectedTags = useMemo(() => (newFilters.tagIds ?? []).map(t => tags[t]), [newFilters.tagIds, tags])
  const leadStatusOptions: LeadStatusItem[] = useMemo(
    () =>
      Array.isArray(leadStatuses?.leadStatuses)
        ? [
            {
              id: null,
              icon: null,
              label: 'No Status',
              labelActiveColor: 'text-gray-900'
            },
            ...leadStatuses.leadStatuses.map(({ id, icon, value }) => ({
              id,
              icon,
              label: value
            }))
          ]
        : [],
    [leadStatuses]
  )
  const suggestedDates = useMemo(() => {
    return ProspectDateOptionsList.filter(({ id }) => id !== (newFilters.dates?.id ?? defaultDateFilter.id)).map(
      ({ id, title }) => ({
        id,
        value: title,
        onClick: () =>
          setDateFilter({
            id,
            title,
            ...Utils.buildDateFilterFromOption(id)
          })
      })
    )
  }, [newFilters.dates?.id, setDateFilter])


  const suggestedAppointmentDates = useMemo(() => {
    return AppointmentDateOptionsList.filter(({ id }) => id !== (newFilters.dates?.id ?? defaultDateFilter.id)).map(
      ({ id, title }) => ({
        id,
        value: title,
        onClick: () =>
          setDateFilter({
            id,
            title,
            ...Utils.buildDateFilterFromOption(id)
          })
      }),
    );
  }, [newFilters.dates?.id, setDateFilter]);

  const datesSuggestionState = useMemo(
    () => ({
      show: !filters?.dates?.id || !newFilters.dates?.id,
      action: {
        text: 'Set a custom date range',
        onClick: openSecondaryModal('dates'),
        icon: null
      },
      options: selectedDateRange.value !== 'appointment_date' ? suggestedDates : suggestedAppointmentDates,
    }),
    [filters?.dates?.id, newFilters.dates?.id, openSecondaryModal, suggestedDates, suggestedAppointmentDates, selectedDateRange]
  )
  const filterCount = useMemo(
    () =>
      +!!newFilters.assignedToUserIds.length +
      +!!newFilters.dates +
      +!!newFilters.leadStatusIds.length +
      +!!newFilters.tagIds.length,
    [newFilters.assignedToUserIds.length, newFilters.dates, newFilters.leadStatusIds.length, newFilters.tagIds.length]
  )
  const suggestedUsers = useMemo(() => {
    const users = teamsList.users
    if (!users || users.length === 0) {
      return []
    }

    const sortedUsers = sortBy(users, user => {
      if (!user || !loggedInUser) return 1

      return user.id === loggedInUser.id ? 0 : 1
    })

    const filteredUsers = newFilters.assignedToUserIds.length
      ? sortedUsers.filter(user => !newFilters.assignedToUserIds.includes(user.id))
      : sortedUsers

    // Take first 5 users and map them to the required format
    return filteredUsers.slice(0, 5).map(({ id, firstName, lastName }) => ({
      id,
      value: `${firstName} ${lastName}`,
      onClick: () => updateAssignedUserFilter(id)
    }))
  }, [newFilters.assignedToUserIds, loggedInUser, teamsList.users, updateAssignedUserFilter])
  const userOptions = useMemo(() => {
    if (!teamsList?.users) {
      return []
    }

    return sortBy(
      Object.values(teamsList.users).map(({ id, firstName, lastName }) => ({
        label: `${firstName} ${lastName}`,
        id
      })),
      ({ label }) => label.toLowerCase()
    )
  }, [teamsList])
  const userSuggestionsState = useMemo(
    () => ({
      show: !filters?.assignedToUserIds.length || !newFilters.assignedToUserIds.length,
      action:
        userOptions.length > suggestedUsers.length
          ? {
              text: 'Browse all team members',
              onClick: openSecondaryModal('users'),
              icon: null
            }
          : null,
      isLoading: isTeamLoading,
      options: suggestedUsers
    }),
    [
      filters?.assignedToUserIds.length,
      newFilters.assignedToUserIds.length,
      openSecondaryModal,
      suggestedUsers,
      isTeamLoading,
      userOptions.length
    ]
  )
  const selectedUsers = useMemo(() => {
    return (newFilters.assignedToUserIds ?? [])
      .map(id => teamsList.users.find(user => user.id === id))
      .filter((user): user is TeamUserResponse => user !== undefined)
  }, [newFilters.assignedToUserIds, teamsList.users])
  const prospectCountString = useMemo(() => {
    // Case 1: Loading state
    if (allProspectCountFetching || filteredProspectCountFetching) {
      return 'Calculating...'
    }
  
    // Case 2: No filters applied - show total count only
    if (!debouncedFilters || Object.values(debouncedFilters).every(value => value === null || (Array.isArray(value) && value.length === 0))) {
      return totalCount !== null 
        ? `${totalCount.toLocaleString()} Prospects` 
        : 'Failed to load prospects';
    }
  
    // Case 3: Filters applied
    if (filteredProspectCount !== null) {
      if (filteredProspectCount === 0) {
        return 'No Results';
      }
      return totalCount !== null
        ? `${filteredProspectCount.toLocaleString()} of ${totalCount.toLocaleString()} Prospects`
        : `${filteredProspectCount.toLocaleString()} Prospects`;
    }
  
    // Default fallback (shouldn't be hit in normal cases)
    return 'Failed to load prospects';
  }, [
    totalCount,
    allProspectCountFetching,
    filteredProspectCount,
    filteredProspectCountFetching,
    debouncedFilters
  ]);

  useEffect(() => {
    if (!leadStatuses.isLoaded && !leadStatuses.isLoading) {
      dispatch(loadLeadStatuses(auth0.getAccessTokenSilently))
    }
    if (!isTagsLoading && !isTagsLoaded) {
      dispatch(loadAllTags(auth0.getAccessTokenSilently))
    }
    if (debouncedFilters && Object.keys(debouncedFilters).length > 0) {
      dispatch(getProspectSummary(auth0.getAccessTokenSilently, { filter: debouncedFilters }))
    } else {
      dispatch(getProspectSummary(auth0.getAccessTokenSilently, {}))
    }
  }, [
    dispatch,
    auth0.getAccessTokenSilently,
    leadStatuses.isLoaded,
    leadStatuses.isLoading,
    isTagsLoading,
    isTagsLoaded,
    newFilters,
    isTeamLoading,
    isTeamLoaded,
    debouncedFilters
  ])

  const handleSelect = (filter: { title: string; description: string; value: string }) => {
    setSelectedDateRange(filter);
  };

  useEffect(() => {
    if (!debouncedFilters || Object.values(debouncedFilters).every(value => value === null || (Array.isArray(value) && value.length === 0))) {
      setTotalCount(allProspectCount); 
    }
  }, [allProspectCount, debouncedFilters]);

  useEffect(() => {
    setNewFilters(filters ?? defaultFilters)
  }, [filters])

  const FilterSectionSummary = (props: { text: string }) => (
    <div className='flex items-center space-x-1'>
      <p className='text-gray-400'>{props.text}</p>
      <ChevronRightIcon className='h-5 w-5 text-gray-400' />
    </div>
  )

  const Footer = (props: { onApply: () => void; onClear: () => void; filterCount: number }) => (
    <div className='flex items-center w-full px-1 filter-footer gap-2'>
      <div className='text-blue-500 font-semibold text-base cursor-pointer' onClick={props.onApply}>
        Done
      </div>
      <div
        className={classNames(
          { 'text-red-500 cursor-pointer': props.filterCount > 0, 'text-gray-500 cursor-not-allowed': props.filterCount === 0 },
          'font-semibold text-base'
        )}
        onClick={props.onClear}
      >
        {props.filterCount > 0
          ? `Clear ${props.filterCount} ${props.filterCount > 1 ? 'Filters' : 'Filter'}`
          : 'Clear Filters'}
      </div>
    </div>
  )

  return (
    <>
      {(showFilters || show) && (
        <Drawer isOpen={show} onClose={handleCloseFilters}>
          <div>
            <ModalHeaderToolbar onBack={onExit} title='Filters' isDisabled={false} isLoading={false} hasShadow />
            <div className='font-normal text-sm text-gray-500 p-3'>{prospectCountString}</div>
            <div>
              <Footer onApply={handleApplyFilter} onClear={handleClearAllFilters} filterCount={filterCount} />
            </div>
            <div className='flex flex-col pb-4'>
              {(teamsList?.users ?? []).length > 1 && (
                <>
                  <div className={`flex flex-col p-4`}>
                    <SectionHeader
                      icon={<UserIcon className='h-4 w-4 text-white' />}
                      title='Assigned to'
                      onPrimaryActionClick={openSecondaryModal('users')}
                      primaryAction={
                        <FilterSectionSummary
                          text={
                            !newFilters.assignedToUserIds.length
                              ? 'Anyone'
                              : `${newFilters.assignedToUserIds.length} Selected`
                          }
                        />
                      }
                    />
                    <UserGroup
                      users={selectedUsers}
                      myId={loggedInUser?.id ?? 1}
                      onClickAdd={openSecondaryModal('users')}
                      suggestions={userSuggestionsState}
                      onDelete={updateAssignedUserFilter}
                    />
                  </div>
                  <Divider />
                </>
              )}
              <div className={`flex flex-col p-4 filter-container`}>
                <SectionHeader
                  icon={<FlameIcon className='h-4 w-4 text-white' />}
                  title='Statuses'
                  onPrimaryActionClick={onClearAllLeadStatuses}
                  primaryAction={
                    newFilters.leadStatusIds.length ? (
                      <Tag text='Deselect All' variant='primary' roundedFull />
                    ) : undefined
                  }
                  containerClassName='pb-2'
                />
                <LeadStatusMultiSelect
                  data={leadStatusOptions as LeadStatusItem[]}
                  activeIds={newFilters.leadStatusIds ?? []}
                  onSelectionChanged={updateLeadStatusFilter}
                />
              </div>
              {tagOptions.length > 0 && (
                <>
                  <Divider />
                  <div className={`flex flex-col p-4`}>
                    <SectionHeader
                      icon={<TagIcon className='h-4 w-4 text-white' />}
                      title='Tags'
                      onPrimaryActionClick={openSecondaryModal('tags')}
                      primaryAction={
                        <FilterSectionSummary
                          text={!newFilters.tagIds.length ? 'All tags' : `${newFilters.tagIds.length} Selected`}
                        />
                      }
                    />
                    <TagsGroup
                      tags={selectedTags}
                      suggestions={tagsSuggestionState}
                      onClickAdd={openSecondaryModal('tags')}
                      onDelete={updateTagFilter}
                    />
                  </div>
                </>
              )}
              <Divider />
              <SectionHeader
                icon={<CalendarIcon className='h-4 w-4 text-white' />}
                title='Date Range'
                onPrimaryActionClick={openSecondaryModal('dates')}
                primaryAction={<FilterSectionSummary text={newFilters.dates?.title ?? defaultDateFilter.title} />}
                containerClassName='p-4'
              />
              <StatusFilterUI selected={selectedDateRange} onSelect={handleSelect} options={statusOptions} />
              <DateQuickSelect {...datesSuggestionState} />
            </div>
          </div>
          <div>
            <TagsModal
              show={showSecondaryModal === 'tags'}
              onExit={closeSecondaryModal}
              onSubmit={handleTagsModalSubmit}
              title='Tags'
              options={tagOptions}
              selected={newFilters.tagIds ?? []}
              headerPrimaryActionText='Apply'
              isTablet={false}
              isPortrait={false}
            />
            <UsersModal
              show={showSecondaryModal === 'users'}
              onExit={closeSecondaryModal}
              onSubmit={handleAssignedUserModalSubmit}
              title='Assigned to'
              options={userOptions}
              selected={newFilters.assignedToUserIds ?? []}
              myId={loggedInUser?.id ?? 1}
            />
            <DateFilterModal
              show={showSecondaryModal === 'dates'}
              onExit={closeSecondaryModal}
              onSubmit={handleDateModalSubmit}
              onReset={handleDateModalReset}
              filter={newFilters.dates}
              isTablet={false}
              isPortrait={false}
            />
          </div>
        </Drawer>
      )}
    </>
  )
}

export default FiltersBottomSheet
