/*
CAMPAIGN CREATOR
 */

import { PortalAPI } from "@platform/api"
import { CampaignMailStatus, CampaignStrategy } from "@platform/types"
import { FeatureCollection } from "geojson"

export enum MailDeliveryType {
  SCHEDULE = 'schedule',
  DELAY = 'delay',
}

export enum ProspectCountStepType {
  CALCULATING = 'calculating',
  INSTRUCTIONS = 'instructions',
  SELECT = 'select',
}

export enum MapInstructionsStepType {
  SELECT = 'select',
  ADJUST = 'adjust',
}

export type CampaignDetailResponse = {
  externalTrackingId: string
  title: string | null
  createdAt: Date
  leadStatusSummary: PortalAPI.CampaignAPI.CampaignListResponse['leadStatusSummary']
  impressionCount: number
  assignedToUserName: string
  assignedToUserId: number | null
  mailStatus: CampaignMailStatus
  strategy: CampaignStrategy
  program: { name: string } | null
  triggerDetails: PortalAPI.CampaignAPI.CampaignListResponse['triggerDetails']
  deliveryWindow: PortalAPI.CampaignAPI.CampaignListResponse['deliveryWindow']
  shape: FeatureCollection | null
}
