import { Drawer, ModalHeaderToolbar, TagOption, TagsInput } from '@platform/ui'
import objectHash from 'object-hash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import type { SelectInstance } from 'react-select'

interface TagFilterModalProps {
  show: boolean
  selected: string[]
  options: TagOption[]
  onExit: () => void
  onSubmit: (tagIds: string[]) => void
  title: string
  headerPrimaryActionText?: string
  isLoading?: boolean
  isTablet: boolean
  isPortrait: boolean
}

export const TagsModal = ({
  show,
  selected,
  options,
  onExit,
  onSubmit,
  title,
  isLoading,
  headerPrimaryActionText,
  isPortrait,
  isTablet
}: TagFilterModalProps) => {
  const inputRef = useRef<SelectInstance<TagOption, true> | null>(null)
  const [newTagIds, setNewTagIds] = useState<string[]>([])

  useEffect(() => {
    setNewTagIds(selected)
  }, [selected, show])

  const addTags = useCallback((tags: readonly TagOption[]) => {
    setNewTagIds(tags.map(t => t.value))
    setTimeout(() => {
      inputRef.current?.focus()
      inputRef.current?.openMenu('first')
    }, 0)
  }, [])

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = useCallback(e => {
    if (e.key === ' ' && !inputRef.current?.inputRef?.value) {
      e.preventDefault()
    }
  }, [])

  const handleSubmit = useCallback(() => onSubmit(newTagIds), [newTagIds, onSubmit])

  useEffect(() => {
    if (inputRef.current?.inputRef) {
      inputRef.current.inputRef.autocapitalize = 'words'
    }
  }, [inputRef])

  const hasUnsavedChanges = useMemo(() => objectHash(newTagIds) !== objectHash(selected), [newTagIds, selected])

  return (
    <div className='prospect-tags-modal'>
      <Drawer isOpen={show} onClose={onExit}>
        <ModalHeaderToolbar
          onBack={onExit}
          onSave={handleSubmit}
          title={title}
          isDisabled={!hasUnsavedChanges || isLoading}
          saveButtonText={headerPrimaryActionText}
          hasShadow
        />
        <div className='flex flex-col pt-6 bg-white'>
          <form>
            <TagsInput
              ref={inputRef}
              creatable={false}
              options={options}
              selectedOptions={newTagIds}
              onTagSelected={addTags}
              handleKeyDown={handleKeyDown}
              isTablet={isTablet}
              isPortrait={isPortrait}
              saveInProgress={isLoading}
            />
          </form>
        </div>
      </Drawer>
    </div>
  )
}
