"use strict";
exports.__esModule = true;
exports.isCompanySurveyCompleteV1 = exports.isCompanySurveyQuestionV1 = exports.CompanySurveyVersion = void 0;
var assert_unreachable_1 = require("./assert-unreachable");
// Survey Questions Versioning:
// Ensure to increment the version number below whenever survey questions are updated.
// Previous Versions: v1
// Current Version: v2
exports.CompanySurveyVersion = 'v2';
function isCompanySurveyQuestionV1(question) {
    var _a;
    var q = question;
    if (q === 'companyUsageIntent' ||
        q === 'companySize' ||
        q === 'companyServices' ||
        q === 'companyD2DExperience' ||
        q === 'companyReferral') {
        return true;
    }
    else {
        return (_a = (0, assert_unreachable_1.assertUnreachableSafe)(q)) !== null && _a !== void 0 ? _a : false;
    }
}
exports.isCompanySurveyQuestionV1 = isCompanySurveyQuestionV1;
var isCompanySurveyCompleteV1 = function (answers) {
    // this is the last question
    return !!answers.companyReferral;
};
exports.isCompanySurveyCompleteV1 = isCompanySurveyCompleteV1;
