import bbox from '@turf/bbox';
import { differenceInSeconds } from 'date-fns';
import { Point, Polygon } from 'geojson';
import { useEffect, useMemo, useRef, useState } from 'react';

type InitialLocation =
  | { type: 'center'; center: [number, number] }
  | { type: 'bounds'; bounds: [[number, number], [number, number]] };
export type HandleSetBoundingBoxFn = (arg: InitialLocation) => void;

type InjectedProps = { initialLocation: InitialLocation | null };

export const useSetInitialBoundingBox = (
  bounds: Polygon | Point | null | undefined,
  handleSetInitialBoundingBox: HandleSetBoundingBoxFn
): InjectedProps => {
  const initialBoundsLoadedAt = useRef<Date>();
  const [initialLocation, setInitialLocation] = useState<InitialLocation | null>(null);

  useEffect(() => {
    if (bounds !== undefined) {
      if (bounds === null) {
        // Just focus around the whole USA
        setInitialLocation({
          type: 'bounds',
          bounds: [
            [-111.157, 43.945],
            [-79.782, 34.835],
          ],
        });
      } else {
        initialBoundsLoadedAt.current ??= new Date();
        // Make sure we don't recenter the map if it's been 5 seconds since we initially centered it
        if (differenceInSeconds(new Date(), initialBoundsLoadedAt.current!) <= 5) {
          const [a, b, c, d] = bbox(bounds);
          if (bounds.type === 'Point') {
            setInitialLocation({
              type: 'center',
              center: [bounds.coordinates[0], bounds.coordinates[1]],
            });
          } else {
            setInitialLocation({
              type: 'bounds',
              bounds: [
                [a, b],
                [c, d],
              ],
            });
          }
        }
      }
    }
  }, [bounds, handleSetInitialBoundingBox]);

  useEffect(() => {
    if (initialLocation) {
      console.info('Setting initial location', initialLocation);
      handleSetInitialBoundingBox(initialLocation);
    }
  }, [handleSetInitialBoundingBox, initialLocation]);

  const injected: InjectedProps = useMemo(
    () => ({
      initialLocation,
    }),
    [initialLocation]
  );

  return injected;
};
