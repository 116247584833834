import { PortalAPI } from '@platform/api';
import { SortParams } from '@platform/api/dist/portal-api/dashboard-api.types';
import { DashboardDateFilter } from '@platform/helpers';
import { Utils } from '@platform/ui-helpers';
import { GetAccessTokenFunction } from '../../types/auth0';
import { Actions, AppThunk } from '../actions';

export const getProspectActivityDashboard: (
  getAccessToken: GetAccessTokenFunction,
  filter: DashboardDateFilter,
  sort?: SortParams
) => AppThunk<Promise<void>> = (getAccessToken, filter, sort) => async (dispatch) => {
  dispatch(Actions.Dashboard.prospectActivityLoading({ filter: filter.id }));
  const queryParams = {
    ...Utils.buildDateFilterQueryParamsFromOption(filter),
    ...(sort || {}),
  };
  const { status, data } = await PortalAPI.Client.getApiClient(getAccessToken).request<
    typeof PortalAPI.DashboardAPI.API['GET /dashboard/lead-status-activity'],
    PortalAPI.DashboardAPI.QueryParams['GET /dashboard/lead-status-activity']
  >({
    route: PortalAPI.DashboardAPI.API['GET /dashboard/lead-status-activity'],
    queryParams: queryParams,
  });

  if (status === 200 && data) {
    dispatch(Actions.Dashboard.leadStatusActivity({ data, filter }));
  }
};

export const getImpressionsDashboard: (
  getAccessToken: GetAccessTokenFunction,
  filter: DashboardDateFilter
) => AppThunk<Promise<void>> = (getAccessToken, filter) => async (dispatch) => {
  dispatch(Actions.Dashboard.impressionsLoading({ filter: filter.id }));
  const { status, data } = await PortalAPI.Client.getApiClient(getAccessToken).request<
    typeof PortalAPI.DashboardAPI.API['GET /dashboard/impressions'],
    PortalAPI.DashboardAPI.QueryParams['GET /dashboard/impressions']
  >({
    route: PortalAPI.DashboardAPI.API['GET /dashboard/impressions'],
    queryParams: Utils.buildDateFilterQueryParamsFromOption(filter),
  });

  if (status === 200 && data) {
    dispatch(Actions.Dashboard.impressions({ data, filter }));
  }
};

export const getTagsDashboard: (
  getAccessToken: GetAccessTokenFunction,
  filter: DashboardDateFilter
) => AppThunk<Promise<void>> = (getAccessToken, filter) => async (dispatch) => {
  dispatch(Actions.Dashboard.impressionsLoading({ filter: filter.id }));
  const { status, data } = await PortalAPI.Client.getApiClient(getAccessToken).request<
    typeof PortalAPI.DashboardAPI.API['GET /dashboard/tag-activity'],
    PortalAPI.DashboardAPI.QueryParams['GET /dashboard/tag-activity']
  >({
    route: PortalAPI.DashboardAPI.API['GET /dashboard/tag-activity'],
    queryParams: Utils.buildDateFilterQueryParamsFromOption(filter),
  });

  if (status === 200 && data) {
    dispatch(Actions.Dashboard.tags({ data, filter }));
  }
};
