import { Auth0ContextInterface, useAuth0, User } from '@auth0/auth0-react';
import { EyeIcon } from '@heroicons/react/20/solid';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { Avatar } from '@platform/ui';
import { Portal, Utils } from '@platform/ui-helpers';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { AppActions } from '../../redux/actions/app-actions';
import { Selectors } from '../../redux/selectors';
import { useTypedDispatch } from '../../redux/state';
import { logout as logoutAction } from '../../redux/thunks';
import { DesktopMenu } from './DesktopMenu';
import { MobileMenu } from './MobileMenu';

export interface WithAuthenticatedPageInjectedProps {
  auth0: Auth0ContextInterface<User>;
}

const getFullName = (first: string | undefined, last: string | undefined) => {
  return first && last ? `${first} ${last}` : undefined;
};

export const withAuthenticatedPageLayout = <T extends WithAuthenticatedPageInjectedProps>(
  Component: React.ComponentType<T>
) => {
  const Page: FunctionComponent<Omit<T, keyof WithAuthenticatedPageInjectedProps>> = (props) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const { user } = useSelector(Selectors.user);
    const loadedCompany = useSelector(Selectors.company);
    const isImpersonating = useSelector(Selectors.isImpersonating);
    const hasPrograms = useSelector(Selectors.hasPrograms);
    const auth0 = useAuth0();
    const location = useLocation();

    // TODO: this is terrible. Make a variation of this authenticated page layout that is for a full screen experience.
    const showMenu = location.pathname !== '/campaigns/new';

    const dispatch = useTypedDispatch();
    const { boot } = useIntercom();

    const name = useMemo(
      () => (user ? getFullName(user.firstName, user.lastName) ?? auth0.user?.name ?? 'User' : ''),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [user, auth0.user?.name]
    );
    const showBanner = useMemo(() => user && !user.isSuperAdmin && isImpersonating, [isImpersonating, user]);

    useEffect(() => {
      if (
        user &&
        !isImpersonating &&
        (!user.isSuperAdmin || Portal.getEnvironment(process.env, 'REACT_APP_IS_PRODUCTION') === 'false')
      ) {
        boot({
          email: user.email,
          createdAt: user.createdAt ? Math.floor(new Date(user.createdAt).getTime() / 1000).toString() : undefined,
          name,
          phone: user.phoneNumber,
          userId: user.externalId,
          userHash: user.intercomUserHash,
          ...(loadedCompany && loadedCompany.externalId && loadedCompany.name
            ? {
                company: {
                  companyId: loadedCompany.externalId,
                  name: loadedCompany.name,
                  createdAt: loadedCompany.createdAt
                    ? Math.floor(new Date(loadedCompany.createdAt).getTime() / 1000).toString()
                    : undefined,
                  customAttributes: {
                    company_stripe_customer_id: loadedCompany?.stripeCustomerId,
                    company_stripe_subscription_id: loadedCompany?.stripeSubscriptionId,
                  },
                },
              }
            : {}),
        });
      }
    }, [boot, user, loadedCompany, name, isImpersonating]);

    useEffect(() => {
      if (showBanner) {
        document.documentElement.style.setProperty('--banner-height', '60px');
      }
    }, [showBanner]);

    const logout = useCallback(
      () =>
        dispatch(
          logoutAction(
            auth0.logout,
            user?.externalId && loadedCompany?.externalId
              ? { id: user.externalId, companyId: loadedCompany.externalId }
              : undefined
          )
        ),
      [auth0.logout, dispatch, loadedCompany.externalId, user?.externalId]
    );

    const exitBirdseyeMode = useCallback(() => {
      dispatch(AppActions.clearCompanyImpersonation());
      window.location.href = Utils.buildUIUrl(window.location.origin, { route: '/' });
    }, [dispatch]);

    const menuProps = useMemo(
      () => ({
        auth0: auth0,
        name: name,
        externalId: loadedCompany?.externalId,
        companyName: loadedCompany?.name,
        isSuperAdmin: !!user?.isSuperAdmin,
        isImpersonating: isImpersonating,
        isSubscribed: !!loadedCompany?.stripeCustomerId,
        hasPrograms,
        hasCampaigns: loadedCompany.hasCampaigns ?? false,
        permissions: user?.permissions,
        logout: logout,
        stopImpersonating: exitBirdseyeMode,
      }),
      [
        auth0,
        exitBirdseyeMode,
        hasPrograms,
        isImpersonating,
        loadedCompany?.externalId,
        loadedCompany.hasCampaigns,
        loadedCompany?.name,
        loadedCompany?.stripeCustomerId,
        user?.isSuperAdmin,
        user?.permissions,
        logout,
        name,
      ]
    );

    return (
      <div className="relative flex h-screen overflow-hidden bg-gray-100">
        {showMenu && (
          <>
            <MobileMenu closeMenu={() => setSidebarOpen(false)} sidebarOpen={sidebarOpen} {...menuProps} />
            <DesktopMenu {...menuProps} />
          </>
        )}
        {/* Body */}
        <div className="flex-1 overflow-auto focus:outline-none">
          {showBanner && (
            <div className="flex items-center gap-x-6 bg-amber-500 py-2.5 px-4 lg:px-10 sm:px-6">
              <div className="flex items-center space-x-3 text-white ">
                <div className="p-2 bg-amber-600 rounded-lg">
                  <EyeIcon height={24} width={24} />
                </div>
                <span className="font-semibold leading-6">
                  You're viewing another company<span className="hidden md:inline-block">, please tread lightly</span>
                </span>
              </div>
              <div className="flex flex-1 justify-end">
                <button
                  type="button"
                  onClick={exitBirdseyeMode}
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-amber-500 bg-white hover:bg-gray-50"
                >
                  Exit<span className="hidden md:inline-block">&nbsp;Mode</span>
                </button>
              </div>
            </div>
          )}
          {/* Mobile Menu Header */}
          {showMenu && (
            <div className="bg-white lg:hidden">
              <div className="relative p-4 pl-1.5 z-10 h-20 bg-white flex items-center justify-between">
                <button
                  type="button"
                  className="w-10 h-10 text-gray-400 text-center hover:bg-blue-50 items-start rounded-full hover:text-blue-500 focus:ring-2 focus:ring-inset focus:ring-blue-500"
                  onClick={() => setSidebarOpen(true)}
                >
                  <span className="sr-only">Open sidebar</span>
                  <Bars3Icon className="h-6 w-6 items-center text-center m-auto" aria-hidden="true" />
                </button>
                <Link to={Portal.getRouteName('/account')}>
                  <Avatar size="large" avatar={{ name }} />
                </Link>
              </div>
            </div>
          )}
          <main className="flex-1 relative z-0">
            <Component {...(props as T)} auth0={auth0} />
          </main>
        </div>
      </div>
    );
  };
  return Page;
};
