import { useCallback, useMemo } from 'react';
import moment from 'moment';
import { CampaignCard, ButtonList} from '@platform/ui';
import './styles.css';
import { sum } from 'lodash';
import { CampaignDetailResponse } from '../../../../types/campaign';
import { PencilIcon } from '@heroicons/react/24/outline';

export type Props = Partial<CampaignDetailResponse> & {
  onViewCampaign: (id: string) => void;
  onRenameTerritory?: (id: string) => void;
};

const CampaignContent = (props: Props) => {
  const {
    title,
    externalTrackingId,
    createdAt,
    assignedToUserName,
    mailStatus,
    program,
    deliveryWindow,
    impressionCount,
    leadStatusSummary,
    onRenameTerritory,
  } = props as Required<Props>;

  const created = useMemo(() => {
    const date = moment(createdAt);
    return `${date.format('MMM D, YYYY [at] h:mma')}`;
  }, [createdAt]);

  const onRenameTerritoryClicked = useCallback(() => {
    onRenameTerritory(externalTrackingId);
  }, [externalTrackingId, onRenameTerritory]);

  if (!props.externalTrackingId) {
    return <></>;
  }

  return (
    <div className="flex flex-col pb-7 w-full transition-all">
      <CampaignCard
        padding="px-6 pb-6"
        isDesktop={false}
        shadow={false}
        onClickCampaign={() => {}}
        title={title || ''}
        address={undefined}
        mailCard={{
          status: mailStatus,
          description: program?.name,
          date: `${deliveryWindow?.start} - ${deliveryWindow?.end}`,
          impressions: impressionCount,
        }}
        representative={{
          name: assignedToUserName,
        }}
        progress={{
          total: 0,
          current: leadStatusSummary ? sum(Object.values(leadStatusSummary).map((s) => s.count)) : 0,
        }}
      />
      <div className="px-5">
        <ButtonList
          data={[
            {
              text: 'Rename Campaign',
              icon: <PencilIcon className="h-5 w-5" aria-hidden="true" />,
              onClick: onRenameTerritoryClicked,
              variant: 'primary',
            },
          ]}
        />
      </div>
      <div className="flex flex-col px-5 mt-5">
        <p className="text-xs font-medium text-gray-500 text-center">{`Created ${created}`}</p>
      </div>
    </div>
  );
};

export default CampaignContent;
