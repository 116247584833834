import cover from '@mapbox/tile-cover';
import bboxPolygon from '@turf/bbox-polygon';
import pointsWithinPolygon from '@turf/points-within-polygon';
import { CanceledError } from 'axios';
import { add } from 'date-fns';
import { difference, flatten, omit, orderBy, throttle, uniqBy } from 'lodash';
import { sha256 } from 'js-sha256';
import { normalizePoint } from '@platform/helpers';
const cache = {};
const dataSignatures = {};
const dataUpdatedCallbacks = {};
const fetchPromise = {};
export const getTileId = ({ z, x, y }) => `${z}/${x}/${y}`;
const initCache = (source) => {
    console.info(`initializing cache for ${source}`);
    cache[source] = {
        recordCache: {},
        tileCache: {},
        zoomLevelCache: {},
        displayCache: {},
        pendingRecords: {},
    };
    dataSignatures[source] = '';
    delete dataUpdatedCallbacks[source];
    return cache[source];
};
function getFeatureType(data) {
    return data.properties.count > 1 ? 'cluster' : 'record';
}
const updateTileCache = (source, fn) => {
    const update = fn(cache[source].tileCache);
    if (update !== undefined) {
        cache[source].tileCache = update;
    }
};
const updateDisplayCache = (source, id, ttlSeconds) => {
    const now = new Date();
    if (ttlSeconds === 0) {
        if (cache[source].displayCache[id]) {
            console.info(`Expiring ${id} in display cache (ttl = 0)`);
            cache[source].displayCache[id].expiresAt = now;
        }
    }
    else {
        const expiresAt = add(now, { seconds: ttlSeconds });
        console.info(`Setting display cache for ${source} ${id} - ${expiresAt}`);
        cache[source].displayCache[id] = { expiresAt };
    }
};
const updateZoomCache = (source, fn) => {
    const update = fn(cache[source].zoomLevelCache);
    if (update !== undefined) {
        cache[source].zoomLevelCache = update;
    }
};
const getRecord = (source, id) => cache[source].recordCache[id];
const getPendingRecord = (source, id) => cache[source].pendingRecords[id];
const handlePendingRecordSequenceCompleted = (source, id, sequence) => {
    const pending = getPendingRecord(source, id);
    if (pending) {
        if (sequence === pending.sequence) {
            console.info(`Pending ${source} ${pending.mutation} sequence completed (${pending.sequence}) for prospect ${id}`);
            delete cache[source].pendingRecords[id];
            return true;
        }
        else if (sequence > pending.sequence) {
            console.warn(`Sequence ${sequence} for ${source} ${id} higher than expected (${pending.sequence})`);
            delete cache[source].pendingRecords[id];
            return true;
        }
        else {
            pending.stack = pending.stack?.filter((s) => s.sequence > sequence) ?? [];
        }
    }
    else {
        console.warn(`${source} with id ${id} cannot be found (sequence ${sequence}). Cannot process pending record sequence.`);
    }
    return false;
};
const rollbackPendingRecord = (source, id, sequence) => {
    const pending = getPendingRecord(source, id);
    if (pending) {
        // If we are rolling back the latest update (happy path)
        if (sequence === pending.sequence) {
            if (pending.stack.length) {
                cache[source].pendingRecords[id] = {
                    ...pending.stack[0],
                    stack: pending.stack.slice(1),
                };
            }
            else {
                delete cache[source].pendingRecords[id];
            }
        }
        else if (sequence > pending.sequence) {
            console.error(`Sequence ${sequence} for ${source} ${id} higher than expected (${pending.sequence})`);
        }
        else {
            const rollback = pending.stack.find((s) => s.sequence === sequence);
            if (rollback && rollback.mutation === 'add') {
                console.warn(`Rolling back a historical "add" mutation. Deleting entire pending record`);
                delete cache[source].pendingRecords[id];
            }
            else {
                console.warn(`Attempting to rollback a historical mutation. We can't really process this request.`);
                pending.stack = pending.stack.filter((s) => s.sequence !== sequence);
            }
        }
    }
    else {
        console.warn(`${source} with id ${id} cannot be found. Nothing to rollback. (Sequence ${sequence})`);
    }
};
const getRecordPublic = (source) => (id) => {
    const record = getRecord(source, id);
    const pending = getPendingRecord(source, id);
    if (pending) {
        return pending.mutation === 'delete' ? undefined : pending.data;
    }
    return record;
};
const updateRecordCache = (source, fn) => {
    const update = fn(cache[source].recordCache);
    if (update !== undefined) {
        cache[source].recordCache = update;
    }
};
const addRecordCache = (source, record, onDataUpdated, getZoomLevel) => {
    const zoom = Math.floor(getZoomLevel());
    console.info(`adding to ${source}`, record);
    const { id: clientId, ...rest } = record;
    const pending = {
        sequence: 0,
        mutation: 'add',
        data: rest,
    };
    cache[source].pendingRecords[clientId] = {
        ...pending,
        stack: [],
    };
    handleDataUpdated(source)(zoom, onDataUpdated);
    return {
        data: rest,
        done: (updates, displayCacheTtlSeconds) => {
            const id = updates?.id ?? clientId;
            if (displayCacheTtlSeconds !== undefined) {
                updateDisplayCache(source, updates?.data?.id ?? id, displayCacheTtlSeconds);
            }
            if (handlePendingRecordSequenceCompleted(source, id, pending.sequence)) {
                const finalRecord = {
                    ...rest,
                    ...(updates?.data?.geometry ? { geometry: updates?.data?.geometry } : {}),
                    ...(updates?.data?.properties
                        ? {
                            properties: {
                                ...rest.properties,
                                ...updates?.data?.properties,
                            },
                        }
                        : {}),
                    id: updates?.data?.id ?? id,
                };
                updateRecordCache(source, (c) => {
                    return {
                        ...c,
                        [updates?.data?.id ?? id]: finalRecord,
                    };
                });
                const tiles = flatten(getCachedZoomLevels(source).map((z) => {
                    return cover.tiles(finalRecord.geometry, {
                        min_zoom: z,
                        max_zoom: z,
                    }).map(([x, y]) => ({ x, y, z }));
                }));
                console.info('purging tiles', tiles);
                const now = new Date();
                updateTileCache(source, (c) => ({
                    ...c,
                    ...tiles.reduce((acc, tile) => {
                        const tileId = getTileId(tile);
                        acc[tileId] = {
                            ...(c[tileId] ?? {}),
                            dirtyAt: now,
                            expiresAt: add(now, { seconds: 3 }),
                            data: {
                                clusters: c[tileId]?.data?.clusters ?? [],
                                records: Array.from(new Set([...(c[tileId]?.data?.records ?? []), updates?.data?.id ?? id])),
                            },
                        };
                        return acc;
                    }, {}),
                }));
                throttledDataUpdated(source)(zoom, onDataUpdated, true);
            }
            return getRecordPublic(source)(updates?.data?.id ?? id);
        },
        rollback: () => {
            rollbackPendingRecord(source, clientId, pending.sequence);
            throttledDataUpdated(source)(zoom, onDataUpdated, true);
        },
    };
};
const updateRecord = (source, onDataUpdated, getZoomLevel) => (id, fn) => {
    const zoom = Math.floor(getZoomLevel());
    const record = getRecord(source, id);
    if (!record) {
        throw new Error(`Could not find ${source} with id ${id}`);
    }
    const updatedRecord = {
        ...record,
        properties: {
            ...fn(record.properties),
            id,
        },
    };
    console.info(`updateRecord ${source}`, record, updatedRecord);
    const pendingRecord = getPendingRecord(source, id);
    // If there's already a pending create or update, just update the sequence and data,
    // and leave the mutation alone
    const pending = {
        mutation: pendingRecord?.mutation ?? 'update',
        data: {
            ...(pendingRecord?.data ?? {}),
            ...updatedRecord,
        },
        sequence: (pendingRecord?.sequence ?? -1) + 1,
    };
    // We want to preserve object references to prevent race conditions
    // Ideally we'd generalize this code for use in add/update/delete functions
    const f = Object.assign(cache[source].pendingRecords[id] ?? {}, pending, cache[source].pendingRecords[id]
        ? {
            stack: [omit(cache[source].pendingRecords[id], ['stack']), ...cache[source].pendingRecords[id].stack],
        }
        : { stack: [] });
    cache[source].pendingRecords[id] ??= f;
    handleDataUpdated(source)(zoom, onDataUpdated);
    return {
        data: updatedRecord,
        done: (updates, displayCacheTtlSeconds) => {
            if (displayCacheTtlSeconds !== undefined) {
                updateDisplayCache(source, id, displayCacheTtlSeconds);
            }
            if (handlePendingRecordSequenceCompleted(source, id, pending.sequence)) {
                const finalUpdate = {
                    ...updatedRecord,
                    ...(updates?.data?.geometry ? { geometry: updates?.data?.geometry } : {}),
                    ...(updates?.data?.properties
                        ? {
                            properties: {
                                ...updatedRecord.properties,
                                ...updates?.data?.properties,
                            },
                        }
                        : {}),
                    id,
                };
                updateRecordCache(source, (c) => {
                    return {
                        ...c,
                        [id]: finalUpdate,
                    };
                });
                const tiles = flatten(getCachedZoomLevels(source).map((z) => {
                    return cover.tiles(finalUpdate.geometry, {
                        min_zoom: z,
                        max_zoom: z,
                    }).map(([x, y]) => ({ x, y, z }));
                }));
                console.info('purging tiles', tiles);
                const now = new Date();
                updateTileCache(source, (c) => ({
                    ...c,
                    ...tiles.reduce((acc, tile) => {
                        const tileId = getTileId(tile);
                        acc[tileId] = {
                            ...(c[tileId] ?? {}),
                            dirtyAt: now,
                            expiresAt: add(now, { seconds: 3 }), // Allow a few seconds for replication
                        };
                        return acc;
                    }, {}),
                }));
                throttledDataUpdated(source)(zoom, onDataUpdated);
            }
            return getRecordPublic(source)(id);
        },
        rollback: () => {
            rollbackPendingRecord(source, id, pending.sequence);
            throttledDataUpdated(source)(zoom, onDataUpdated);
        },
    };
};
const deleteRecordCache = (source, id, onDataUpdated, getZoomLevel) => {
    const zoom = Math.floor(getZoomLevel());
    const record = getRecord(source, id);
    if (!record) {
        console.error(`Could not find ${source} with id ${id}`);
        throw new Error(`Could not find ${source} with id ${id}`);
    }
    else {
        console.info(`Deleting ${source} with id ${id}`);
    }
    const pendingRecord = getPendingRecord(source, id);
    // It doesn't matter what else is pending, we should treat it as a delete
    const pending = {
        mutation: 'delete',
        data: {
            ...record,
            ...(pendingRecord ?? {}),
        },
        sequence: (pendingRecord?.sequence ?? -1) + 1,
    };
    // We want to preserve object references to prevent race conditions
    // Ideally we'd generalize this code for use in add/update/delete functions
    const f = Object.assign(cache[source].pendingRecords[id] ?? {}, pending, cache[source].pendingRecords[id]
        ? {
            stack: [omit(cache[source].pendingRecords[id], ['stack']), ...cache[source].pendingRecords[id].stack],
        }
        : { stack: [] });
    cache[source].pendingRecords[id] ??= f;
    handleDataUpdated(source)(zoom, onDataUpdated);
    return {
        data: record,
        done: () => {
            if (handlePendingRecordSequenceCompleted(source, id, pending.sequence)) {
                updateRecordCache(source, (c) => {
                    delete c[id];
                    return c;
                });
                const tileIds = flatten(getCachedZoomLevels(source).map((z) => {
                    return cover
                        .tiles(record.geometry, {
                        min_zoom: z,
                        max_zoom: z,
                    })
                        .map(([x, y]) => getTileId({ x, y, z }));
                }));
                console.info('purging tiles', tileIds);
                const now = new Date();
                updateTileCache(source, (c) => ({
                    ...c,
                    ...tileIds.reduce((acc, tileId) => {
                        acc[tileId] = {
                            ...(c[tileId] ?? {}),
                            dirtyAt: now,
                            expiresAt: add(now, { seconds: 3 }),
                            data: !c[tileId]?.data
                                ? c[tileId]?.data
                                : {
                                    records: c[tileId].data?.records.filter((d) => d !== id) ?? [],
                                    clusters: c[tileId]?.data?.clusters ?? [],
                                },
                        };
                        return acc;
                    }, {}),
                }));
                delete cache[source].displayCache[id];
                throttledDataUpdated(source)(zoom, onDataUpdated);
            }
        },
        rollback: () => {
            rollbackPendingRecord(source, id, pending.sequence);
            throttledDataUpdated(source)(zoom, onDataUpdated);
        },
    };
};
const clearCaches = (source, onDataUpdated, getZoomLevel) => () => {
    initCache(source);
    handleDataUpdated(source)(getZoomLevel(), onDataUpdated, true);
};
const addRecord = (source, onDataUpdated, getZoomLevel) => (record) => addRecordCache(source, record, onDataUpdated, getZoomLevel);
const deleteRecord = (source, onDataUpdated, getZoomLevel) => (id) => deleteRecordCache(source, id, onDataUpdated, getZoomLevel);
const handleDataUpdated = (source) => {
    return (zoom, onDataUpdated, force = false) => {
        const tileData = (cache[source].zoomLevelCache[Math.floor(zoom)] ?? []).map((tileId) => cache[source].tileCache[tileId].data);
        if (tileData.every((d) => !d) && !force) {
            return;
        }
        console.info(`handleDataUpdated ${source} - zoom ${zoom}`);
        const features = orderBy(difference(uniqBy([
            ...flatten(tileData.map((data) => [
                ...(data?.clusters ?? []),
                ...(data?.records ?? []).map((d) => {
                    const pendingRecord = cache[source].pendingRecords[d]?.mutation === 'update'
                        ? cache[source].pendingRecords[d].data
                        : null;
                    return pendingRecord ?? cache[source].recordCache[d];
                }),
            ])),
            ...Object.keys(cache[source].pendingRecords)
                .filter((id) => cache[source].pendingRecords[id].mutation === 'add')
                .map((d) => cache[source].pendingRecords[d].data),
            ...Object.keys(cache[source].displayCache).map((d) => cache[source].recordCache[d]),
        ], (f) => f.properties.id), Object.keys(cache[source].pendingRecords)
            .filter((id) => cache[source].pendingRecords[id].mutation === 'delete')
            .map((id) => cache[source].pendingRecords[id].data)), (f) => f.properties.id);
        const fc = {
            type: 'FeatureCollection',
            features,
        };
        // Note: normally we'd use object-hash here but object-hash is slow for large objects. sha256-js is much faster (like > 10x faster)
        const newSignature = sha256(JSON.stringify(fc));
        if (newSignature !== dataSignatures[source] || force) {
            dataSignatures[source] = newSignature;
            onDataUpdated(fc);
        }
    };
};
const throttledDataUpdated = (source) => {
    if (!dataUpdatedCallbacks[source]) {
        dataUpdatedCallbacks[source] = throttle(handleDataUpdated(source), 500, {
            leading: true,
            trailing: true,
        });
    }
    return dataUpdatedCallbacks[source];
};
const getCachedZoomLevels = (source) => Object.keys(cache[source].zoomLevelCache).map(Number);
const fetchTiles = async (source, z, tilesToFetch, fetchTileFn, onDataUpdated, force = false) => {
    if (tilesToFetch.length > 0) {
        console.info(`Fetching ${source} tiles for z ${z} - ${tilesToFetch.length} tiles`);
        const now = new Date();
        // Set the requestedAt timestamp before we request the tiles to make sure we don't squash any local data
        updateTileCache(source, (c) => ({
            ...c,
            ...tilesToFetch
                .map((t) => getTileId({ ...t, z }))
                .reduce((acc, tileId) => {
                acc[tileId] = {
                    ...(c[tileId] ?? {}),
                    requestedAt: now,
                };
                return acc;
            }, {}),
        }));
        const p = fetchPromise[source];
        if (p && p[1]) {
            console.info(`aborting fetch for ${source}`);
            p[1]();
        }
        const { promise, abort } = fetchTileFn(z, tilesToFetch);
        fetchPromise[source] = [promise, abort];
        try {
            const tileData = await fetchPromise[source][0];
            updateTileCache(source, (c) => {
                return {
                    ...c,
                    ...Object.keys(tileData).reduce((acc, tileId) => {
                        if (!!c[tileId]?.dirtyAt && !!c[tileId]?.requestedAt && c[tileId].requestedAt < c[tileId].dirtyAt) {
                            console.info(`skipping overwrite of ${source} tile ${tileId} because it is dirty`);
                            return acc;
                        }
                        acc[tileId] = {
                            ...c[tileId],
                            dirtyAt: undefined,
                            expiresAt: add(c[tileId]?.requestedAt ?? now, { minutes: 30 }),
                            data: tileData[tileId].features.reduce((acc, { id, ...feature }) => {
                                if (getFeatureType(feature) === 'cluster') {
                                    acc.clusters.push({ ...feature, properties: { ...feature.properties, id: id } });
                                }
                                else if (getFeatureType(feature) === 'record') {
                                    acc.records.push(id);
                                }
                                return acc;
                            }, { clusters: [], records: [] }),
                        };
                        return acc;
                    }, {}),
                };
            });
            updateRecordCache(source, (c) => {
                return {
                    ...c,
                    ...Object.keys(tileData).reduce((acc, tileId) => {
                        acc = {
                            ...acc,
                            ...tileData[tileId].features.reduce((features, feature) => {
                                if (getFeatureType(feature) === 'record') {
                                    const { id, geometry, ...rest } = feature;
                                    features[id] = {
                                        ...rest,
                                        geometry: normalizePoint(geometry),
                                        properties: {
                                            ...feature.properties,
                                            id: id,
                                        },
                                    };
                                }
                                return features;
                            }, {}),
                        };
                        return acc;
                    }, {}),
                };
            });
            updateZoomCache(source, (c) => {
                return {
                    ...c,
                    [z]: Array.from(new Set([...(c[z] ?? []), ...Object.keys(tileData)])),
                };
            });
        }
        catch (e) {
            const tileIds = tilesToFetch.map(({ x, y }) => getTileId({ z, x, y }));
            // Just to reduce noise. Canceled Errors are expected.
            if (!(e instanceof CanceledError)) {
                console.error(`Failed fetching tiles for ${source} at z ${z}: ${tileIds.join(', ')}`, e);
            }
            updateTileCache(source, (c) => {
                for (const tileId of tileIds) {
                    if (c[tileId] && !c[tileId].data) {
                        delete c[tileId];
                    }
                }
                return c;
            });
        }
        finally {
            delete fetchPromise[source];
        }
    }
    throttledDataUpdated(source)(z, onDataUpdated, force);
};
const onMapMoved = (source, fetchTileFn, onDataUpdated) => (bounds, zoom, force = false) => {
    const boundingPolygon = bboxPolygon([
        bounds.nw.coordinates[0],
        bounds.nw.coordinates[1],
        bounds.se.coordinates[0],
        bounds.se.coordinates[1],
    ]);
    let tiles = cover.tiles(boundingPolygon.geometry, {
        min_zoom: zoom,
        max_zoom: zoom,
    });
    // Sometimes we'll get a really big bounding box at a high zoom, which is not good.
    if (tiles.length > 30) {
        console.warn(`Too many tiles to fetch for ${source} at zoom ${zoom}: ${tiles.length}`);
        return;
    }
    // Check if there are any expired display cache records in this bounding box.
    // If so, invalidate the tiles (for all zoom levels we have cached), and force a refetch of the current zoom level's tiles
    // Then purge them from the display cache
    const now = new Date();
    const expiredDisplayCache = Object.keys(cache[source].displayCache).reduce((ids, id) => {
        if (cache[source].displayCache[id].expiresAt < now) {
            ids.push(cache[source].recordCache[id]);
        }
        return ids;
    }, []);
    if (expiredDisplayCache.length) {
        const intersectingPoints = pointsWithinPolygon({
            type: 'FeatureCollection',
            features: expiredDisplayCache,
        }, boundingPolygon);
        if (intersectingPoints.features.length) {
            console.info(`${intersectingPoints.features.length} expired display cache ${source} records in the current view`);
            // Find all tiles that might need to be expired
            const displayTilesToExpire = intersectingPoints.features.reduce((tileIds, feature) => {
                const tiles = flatten(getCachedZoomLevels(source).map((z) => {
                    return cover
                        .tiles(feature.geometry, {
                        min_zoom: z,
                        max_zoom: z,
                    })
                        .map(([x, y]) => ({ tileId: getTileId({ x, y, z }), tile: [x, y, z] }));
                }));
                tileIds = uniqBy([...tileIds, ...tiles], (t) => t.tileId);
                return tileIds;
            }, []);
            console.info(`${displayTilesToExpire.length} ${source} tiles to expire`);
            // Invalidate/expire the tiles
            for (const { tileId } of displayTilesToExpire) {
                const tile = cache[source].tileCache[tileId];
                if (tile) {
                    tile.expiresAt = now;
                }
            }
            // Remove the record from the display cache now that it's been invalidated;
            for (const point of intersectingPoints.features) {
                delete cache[source].displayCache[point.properties.id];
            }
            // Add the tiles for the current zoom level to be automatically fetched
            tiles = [...tiles, ...displayTilesToExpire.filter(({ tile }) => tile[2] === zoom).map(({ tile }) => tile)];
        }
    }
    const tilesToFetch = tiles
        .filter(([x, y, z]) => {
        const cachedTile = cache[source].tileCache[getTileId({ x, y, z })];
        return (z === zoom &&
            (!cachedTile || ('expiresAt' in cachedTile && !!cachedTile.expiresAt && cachedTile.expiresAt <= now)));
    })
        .map(([x, y]) => ({ x, y }));
    fetchTiles(source, zoom, tilesToFetch, fetchTileFn, onDataUpdated, force);
};
export const rerender = (source, onDataUpdated, getZoomLevel) => {
    return () => {
        handleDataUpdated(source)(Math.floor(getZoomLevel()), onDataUpdated, true);
    };
};
const getPendingRecordPublic = (source) => (id) => getPendingRecord(source, id);
export const create = (params) => {
    initCache(params.source);
    return {
        onMapMoved: onMapMoved(params.source, params.fetch, params.onDataUpdated),
        clearCaches: clearCaches(params.source, params.onDataUpdated, params.getZoomLevel),
        addRecord: addRecord(params.source, params.onDataUpdated, params.getZoomLevel),
        updateRecord: updateRecord(params.source, params.onDataUpdated, params.getZoomLevel),
        deleteRecord: deleteRecord(params.source, params.onDataUpdated, params.getZoomLevel),
        getRecord: getRecordPublic(params.source),
        getPendingRecord: getPendingRecordPublic(params.source),
        rerender: rerender(params.source, params.onDataUpdated, params.getZoomLevel),
    };
};
