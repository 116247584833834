import { useCallback, useMemo, useEffect, useState } from 'react';
import { ModalHeaderToolbar, ProspectInfoUI, ProspectInfoUIData, SectionType } from '@platform/ui';
import { useSelector } from 'react-redux';
import { useTypedDispatch } from '../../redux/state';
import { Selectors } from '../../redux/selectors';
import { GetAccessTokenFunction } from '../../types/auth0';
import { AppActions } from '../../redux/actions/app-actions';
import { getProspect } from '../../redux/thunks';
import { assertUnreachableSafe } from '@platform/helpers';
import { NotificationType } from '../../types/notification';

export const ProspectInfoModal = ({
  getAccessTokenSilently,
  prospectId,
}: {
  getAccessTokenSilently: GetAccessTokenFunction;
  prospectId: string;
}) => {
  const prospectFromStore = useSelector(Selectors.prospectById(prospectId));
  const dispatch = useTypedDispatch();

  const [prospect, setProspect] = useState(prospectFromStore);

  useEffect(() => {
    if (prospectFromStore) {
      setProspect(prospectFromStore);
    }
  }, [prospectFromStore]);

  const onClose = useCallback(() => {
    dispatch(AppActions.setProspectInfoModal(null));
  }, [dispatch]);

  const onEdit = useCallback(
    (type: SectionType) => {
      if (prospectId) {
        if (type === 'contact') {
          dispatch(AppActions.setProspectsContactModal({ prospectId }));
        } else if (type === 'address') {
          dispatch(AppActions.setProspectAddressModal({ prospectId }));
        } else {
          assertUnreachableSafe(type);
        }
      }
    },
    [dispatch, prospectId]
  );

  const onNavigateToAddress = useCallback(async (address: string) => {
    const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
    window.open(url, '_blank');
  }, []);

  const onCopyAddress = useCallback(
    async (address: string) => {
      try {
        await navigator.clipboard.writeText(address);
        dispatch(
          AppActions.notification({
            type: NotificationType.success,
            title: 'Address copied',
            placement: 'lower-right',
          })
        );
      } catch (e) {
        dispatch(
          AppActions.notification({
            type: NotificationType.error,
            title: 'Failed to copy address',
            placement: 'lower-right',
          })
        );
      }
    },
    [dispatch]
  );

  const data: ProspectInfoUIData | null = useMemo(
    () =>
      !!prospect
        ? {
            name: prospect.name ?? undefined,
            emailAddress: prospect.emailAddress ?? undefined,
            phoneNumber: prospect.phoneNumber ?? undefined,
            secondaryPhoneNumber: prospect.secondaryPhoneNumber ?? undefined,
            address1: prospect.address.address1,
            address2: prospect.address.address2 ?? undefined,
            city: prospect.address.city,
            state: prospect.address.state,
            zip: prospect.address.zip,
            onCopyAddress,
            onNavigateToAddress,
          }
        : null,
    [onCopyAddress, onNavigateToAddress, prospect]
  );

  useEffect(() => {
    if (prospectId && (!prospect || !prospect.createdAt)) {
      dispatch(getProspect(prospectId, getAccessTokenSilently));
    }
  }, [dispatch, prospectId, getAccessTokenSilently, prospect]);

  return (
    <>
      <ModalHeaderToolbar onBack={onClose} title="Prospect Info" hasShadow />
      <div className="mt-4">
        {!!data && !!prospect && <ProspectInfoUI isMobileApp={false} onEdit={onEdit} {...data} />}
      </div>
    </>
  );
};
