"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
exports.__esModule = true;
exports.getSubscriptionDetails = exports.getDefaultPaymentMethod = exports.tryResumeSubscription = exports.getCustomer = exports.getEntityId = exports.configureSaveDefaultPaymentMethod = exports.subscriptionIsCanceled = exports.subscriptionIsPaused = exports.subscriptionHasPaymentIssue = exports.subscriptionIsActiveOrPaymentIssues = exports.subscriptionIsActive = exports.checkStripeSubscription = void 0;
var app_config_1 = require("@platform/app-config");
var lodash_1 = require("lodash");
var checkStripeSubscription = function (stripe, subscriptionId) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, stripe.subscriptions.retrieve(subscriptionId).then(function (subscription) {
                return __assign({ isActive: subscriptionIsActive(subscription.status), isPaused: subscriptionIsPaused(subscription.status), isCanceled: subscriptionIsCanceled(subscription.status), hasPaymentIssue: subscriptionHasPaymentIssue(subscription.status) }, subscription);
            }, 
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            function (e) {
                console.error(e);
                // This is a bit janky. I'm supposed to be able to check `e instanceof Stripe.errors.StripeInvalidRequestError` but it doesn't work
                // Basically if the subscription doesn't exist, it's because the user hasn't gone through the checkout flow yet
                if ('type' in e && e.type === 'StripeInvalidRequestError' && e.code === 'resource_missing') {
                    return { isActive: false, isCanceled: true, isPaused: false, hasPaymentIssue: false, status: 'canceled' };
                }
                // This is likely an error with the Stripe API so let's just pretend everything is okay
                return { isActive: true, isCanceled: false, isPaused: false, hasPaymentIssue: false, status: 'active' };
            })];
    });
}); };
exports.checkStripeSubscription = checkStripeSubscription;
function subscriptionIsActive(status) {
    return status === 'active' || status === 'trialing';
}
exports.subscriptionIsActive = subscriptionIsActive;
function subscriptionIsActiveOrPaymentIssues(status) {
    return status === 'active' || status === 'trialing' || status === 'past_due' || status === 'unpaid';
}
exports.subscriptionIsActiveOrPaymentIssues = subscriptionIsActiveOrPaymentIssues;
function subscriptionHasPaymentIssue(status) {
    return status === 'past_due' || status === 'unpaid' || status === 'incomplete' || status === 'incomplete_expired';
}
exports.subscriptionHasPaymentIssue = subscriptionHasPaymentIssue;
function subscriptionIsPaused(status) {
    return status === 'paused';
}
exports.subscriptionIsPaused = subscriptionIsPaused;
function subscriptionIsCanceled(status) {
    return status === 'canceled';
}
exports.subscriptionIsCanceled = subscriptionIsCanceled;
var configureSaveDefaultPaymentMethod = function (stripe, subscription) { return __awaiter(void 0, void 0, void 0, function () {
    var sub, _a;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (!(typeof subscription === 'string')) return [3 /*break*/, 2];
                return [4 /*yield*/, stripe.subscriptions.retrieve(subscription)];
            case 1:
                _a = _c.sent();
                return [3 /*break*/, 3];
            case 2:
                _a = subscription;
                _c.label = 3;
            case 3:
                sub = _a;
                if (((_b = sub.payment_settings) === null || _b === void 0 ? void 0 : _b.save_default_payment_method) !== 'on_subscription') {
                    return [2 /*return*/, stripe.subscriptions.update(sub.id, {
                            payment_settings: { save_default_payment_method: 'on_subscription' },
                            expand: ['customer']
                        })];
                }
                return [2 /*return*/, sub];
        }
    });
}); };
exports.configureSaveDefaultPaymentMethod = configureSaveDefaultPaymentMethod;
var getEntityId = function (o) { var _a; return typeof o === 'string' ? o : (_a = o === null || o === void 0 ? void 0 : o.id) !== null && _a !== void 0 ? _a : null; };
exports.getEntityId = getEntityId;
var getCustomer = function (customer, stripe) { return __awaiter(void 0, void 0, void 0, function () {
    var c;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!(typeof customer === 'string')) return [3 /*break*/, 2];
                return [4 /*yield*/, stripe.customers.retrieve(customer)];
            case 1:
                c = _a.sent();
                return [2 /*return*/, c.deleted ? null : c];
            case 2:
                if (customer.deleted) {
                    return [2 /*return*/, null];
                }
                else {
                    return [2 /*return*/, customer];
                }
                _a.label = 3;
            case 3: return [2 /*return*/];
        }
    });
}); };
exports.getCustomer = getCustomer;
var tryResumeSubscription = function (stripe, subscription) { return __awaiter(void 0, void 0, void 0, function () {
    var sub, _a, paymentMethodId, s, e_1;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!(typeof subscription === 'string')) return [3 /*break*/, 2];
                return [4 /*yield*/, stripe.subscriptions.retrieve(subscription, { expand: ['customer'] })];
            case 1:
                _a = _b.sent();
                return [3 /*break*/, 3];
            case 2:
                _a = subscription;
                _b.label = 3;
            case 3:
                sub = _a;
                if (!subscriptionIsPaused(sub.status)) return [3 /*break*/, 10];
                return [4 /*yield*/, (0, exports.getDefaultPaymentMethod)(stripe, sub)];
            case 4:
                paymentMethodId = (_b.sent()).method;
                if (!paymentMethodId) return [3 /*break*/, 10];
                _b.label = 5;
            case 5:
                _b.trys.push([5, 9, , 10]);
                return [4 /*yield*/, stripe.subscriptions.resume(sub.id, {
                        billing_cycle_anchor: 'now',
                        expand: ['customer']
                    })];
            case 6:
                s = _b.sent();
                if (!s.latest_invoice) return [3 /*break*/, 8];
                // The subscription won't resume until the invoice is paid
                return [4 /*yield*/, stripe.invoices.pay((0, exports.getEntityId)(s.latest_invoice))];
            case 7:
                // The subscription won't resume until the invoice is paid
                _b.sent();
                _b.label = 8;
            case 8: return [2 /*return*/, s];
            case 9:
                e_1 = _b.sent();
                console.error('Failed resuming subscription', e_1);
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/, sub];
        }
    });
}); };
exports.tryResumeSubscription = tryResumeSubscription;
var getDefaultPaymentMethod = function (stripe, subscription) { return __awaiter(void 0, void 0, void 0, function () {
    var sub, _a, method;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (!(typeof subscription === 'string' || typeof subscription.customer === 'string')) return [3 /*break*/, 2];
                return [4 /*yield*/, stripe.subscriptions.retrieve((0, exports.getEntityId)(subscription), { expand: ['customer'] })];
            case 1:
                _a = _c.sent();
                return [3 /*break*/, 3];
            case 2:
                _a = subscription;
                _c.label = 3;
            case 3:
                sub = _a;
                method = (_b = (sub.default_payment_method
                    ? { type: 'payment_method', id: (0, exports.getEntityId)(sub.default_payment_method) }
                    : typeof sub.customer !== 'string' && !sub.customer.deleted
                        ? sub.customer.invoice_settings.default_payment_method
                            ? { type: 'payment_method', id: (0, exports.getEntityId)(sub.customer.invoice_settings.default_payment_method) }
                            : null
                        : null)) !== null && _b !== void 0 ? _b : (sub.default_source
                    ? { type: 'source', id: (0, exports.getEntityId)(sub.default_source) }
                    : typeof sub.customer !== 'string' && !sub.customer.deleted
                        ? sub.customer.default_source
                            ? { type: 'source', id: (0, exports.getEntityId)(sub.customer.default_source) }
                            : null
                        : null);
                return [2 /*return*/, { method: method, subscription: sub }];
        }
    });
}); };
exports.getDefaultPaymentMethod = getDefaultPaymentMethod;
function getSubscriptionDetails(stripe, subscriptionId, stripeEnvironment) {
    return __awaiter(this, void 0, void 0, function () {
        var subscription, allPriceIds_1, allProducts_1, plan, name_1, _a, e_2;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 4, , 5]);
                    return [4 /*yield*/, stripe.subscriptions.retrieve(subscriptionId)];
                case 1:
                    subscription = _c.sent();
                    allPriceIds_1 = (0, lodash_1.flatten)(Object.keys(app_config_1.ALL_SUBSCRIPTION_PLANS).map(function (p) {
                        var plan = app_config_1.ALL_SUBSCRIPTION_PLANS[p];
                        return ('priceIds' in plan ? plan.priceIds(stripeEnvironment) : []).map(function (d) { return (__assign({ plan: p }, d)); });
                    }));
                    allProducts_1 = (0, lodash_1.compact)(Object.keys(app_config_1.ALL_SUBSCRIPTION_PLANS).map(function (p) {
                        var plan = app_config_1.ALL_SUBSCRIPTION_PLANS[p];
                        if ('productId' in plan) {
                            return {
                                plan: p,
                                productId: plan.productId(stripeEnvironment)
                            };
                        }
                    }));
                    plan = (0, lodash_1.compact)(subscription.items.data
                        .filter(function (_a) {
                        var price = _a.price;
                        return !!price.product;
                    })
                        .map(function (si) {
                        var _a, _b, _c;
                        // Find the plan that has a monthly/annual price ID matching the stripe price ID
                        // First match the prices since that's most specific and fall back to the product
                        var p = (_a = allPriceIds_1.find(function (d) { return d.id === si.price.id; })) !== null && _a !== void 0 ? _a : allProducts_1.find(function (e) { return e.productId === si.price.product; });
                        if (p) {
                            return {
                                id: p.plan,
                                stripeInterval: (_b = si.price.recurring) === null || _b === void 0 ? void 0 : _b.interval,
                                stripeIntervalCount: (_c = si.price.recurring) === null || _c === void 0 ? void 0 : _c.interval_count,
                                product: si.price.product,
                                unitAmount: si.price.unit_amount,
                                quantity: si.quantity,
                                priceId: 'id' in p ? p.id : null
                            };
                        }
                    })).map(function (p) {
                        var _a;
                        var interval = p.stripeInterval === 'year'
                            ? 'annual'
                            : p.stripeInterval === 'month'
                                ? (p === null || p === void 0 ? void 0 : p.stripeIntervalCount) === 1
                                    ? 'monthly'
                                    : (p === null || p === void 0 ? void 0 : p.stripeIntervalCount) === 3
                                        ? 'quarterly'
                                        : (p === null || p === void 0 ? void 0 : p.stripeIntervalCount) === 6
                                            ? 'semiannual'
                                            : null
                                : null;
                        return {
                            id: p.id,
                            interval: interval,
                            priceId: p.id,
                            product: p.product,
                            unitAmount: p === null || p === void 0 ? void 0 : p.unitAmount,
                            quantity: (_a = p === null || p === void 0 ? void 0 : p.quantity) !== null && _a !== void 0 ? _a : 1
                        };
                    })[0];
                    if (!plan) {
                        return [2 /*return*/, { error: 'Unable to find subscription plan' }];
                    }
                    return [4 /*yield*/, stripe.products.retrieve(plan.product)];
                case 2:
                    name_1 = (_c.sent()).name;
                    _a = [__assign({}, plan)];
                    _b = { productName: name_1, details: app_config_1.ALL_SUBSCRIPTION_PLANS[plan.id] };
                    return [4 /*yield*/, (0, exports.checkStripeSubscription)(stripe, subscriptionId)];
                case 3: return [2 /*return*/, __assign.apply(void 0, _a.concat([(_b.status = _c.sent(), _b.subscription = subscription, _b)]))];
                case 4:
                    e_2 = _c.sent();
                    console.error('Unable to get subscription');
                    return [2 /*return*/, { error: "Unable to find subscription plan. ".concat(e_2) }];
                case 5: return [2 /*return*/];
            }
        });
    });
}
exports.getSubscriptionDetails = getSubscriptionDetails;
