import { useAuth0 } from '@auth0/auth0-react';
import { ChartBarIcon } from '@heroicons/react/24/solid';
import { CustomDateFilterWithDescription, DateOptionDescriptions } from '@platform/helpers';
import { breakpoints, Dashboard as DashboardUI, DashboardProps } from '@platform/ui';
import { Utils } from '@platform/ui-helpers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '../../hooks/useMediaQuery';
import { LeaderboardCustomDateModal } from '../../modals/CustomDateRange';
import { DashboardActions } from '../../redux/actions/dashboard-actions';
import { Selectors } from '../../redux/selectors';
import { useTypedDispatch } from '../../redux/state';
import {
  createScoutingReport,
  getImpressionsDashboard,
  getProspectActivityDashboard,
  getTagsDashboard,
  setInitialPage,
} from '../../redux/thunks';
import { withAuthenticatedPageLayout } from '../Layout/authenticated-page';
import { PageHeader } from '../Layout/PageHeader';
import { DateFilter } from './date-filter';

export const Dashboard = withAuthenticatedPageLayout(() => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const isLg = useMediaQuery(breakpoints.xl);
  const isMd = useMediaQuery(breakpoints.md);
  const isSm = useMediaQuery(breakpoints.xs);
  const { getAccessTokenSilently } = useAuth0();
  const { hasCampaigns, isLoaded: companyIsLoaded } = useSelector(Selectors.company);
  const dateFilter = useSelector(Selectors.selectedDateFilter);
  const {
    data: leadStatusActivityData,
    leadStatuses,
    isLoading: leadStatusActivityLoading,
  } = useSelector(Selectors.leadStatusActivity);
  const {
    data: tagActivityData,
    isLoaded: tagActivityLoaded,
    isLoading: tagActivityLoading,
  } = useSelector(Selectors.tagActivity);

  const [reportIsLoading, setReportIsLoading] = useState(false);
  const [showCustomDateModal, setShowCustomDateModal] = useState(false);
  const [showReports, setShowReports] = useState(false);
  const [sortOrders, setSortOrders] = useState<Record<string, 'ASC' | 'DESC'>>({});

  useEffect(() => {
    if (companyIsLoaded) {
      setShowReports(true);
      if (!hasCampaigns) {
        dispatch(setInitialPage(navigate));
      }
    }
  }, [companyIsLoaded, dispatch, hasCampaigns, navigate]);

  useEffect(() => {
    dispatch(getProspectActivityDashboard(getAccessTokenSilently, dateFilter));
    dispatch(getImpressionsDashboard(getAccessTokenSilently, dateFilter));
    dispatch(getTagsDashboard(getAccessTokenSilently, dateFilter));
  }, [dateFilter, dispatch, getAccessTokenSilently]);

  const onSelectDate: DashboardProps['onSelectDate'] = useCallback(
    (filter) => {
      setShowCustomDateModal(filter.id === 'custom-range');
      if (filter.id !== dateFilter.id && filter.id !== 'custom-range') {
        dispatch(
          DashboardActions.setDateFilter({
            ...filter,
            ...Utils.buildDateFilterFromOption(filter.id),
          })
        );
      }
    },
    [dateFilter.id, dispatch]
  );

  const onExitDateModal = useCallback(() => setShowCustomDateModal(false), []);
  const handleDateModalSubmit = useCallback(
    (value: CustomDateFilterWithDescription | null) => {
      dispatch(DashboardActions.setDateFilter(value ?? DateOptionDescriptions.all));
      setShowCustomDateModal(false);
    },
    [dispatch]
  );

  const handleCreateReport = useCallback(async () => {
    setReportIsLoading(true);
    await dispatch(createScoutingReport(getAccessTokenSilently, { dates: dateFilter }));
    setReportIsLoading(false);
  }, [dateFilter, dispatch, getAccessTokenSilently]);

  const sortableColumns = useMemo(() => {
    const columns = [
      ...leadStatuses?.map((s) => ({
        id: s.id,
        sortOrder: sortOrders[s.id] || 'DESC',
      })) || [],
      {
        id: 'total',
        sortOrder: sortOrders['total'] || 'DESC',
      },
    ];
    return columns;
  }, [leadStatuses, sortOrders]);

  const handleSortIconClick = useCallback((columnId: number | string) => {
    const currentSortOrder = sortOrders[columnId] || 'DESC';
    const newSortOrder = currentSortOrder === 'DESC' ? 'ASC' : 'DESC';
    
    setSortOrders((prev) => ({
      ...prev,
      [columnId]: newSortOrder,
    } as Record<string, 'ASC' | 'DESC'>));
  
    const sortField = columnId === 'total' ? 'total' : `status_${columnId}`;
    dispatch(getProspectActivityDashboard(getAccessTokenSilently, dateFilter, {
      sortOrder: newSortOrder,
      sortField,
    }));
  }, [dateFilter, dispatch, getAccessTokenSilently, sortOrders]);

  return !showReports ? (
    <></>
  ) : (
    <div>
      <PageHeader icon={ChartBarIcon} iconHeading="Dashboard" title="Reports" lgMaxWidth="lg:max-w-7xl" />
      <DashboardUI
        dateFilter={dateFilter}
        isPortal
        isLg={isLg}
        isMd={isMd}
        isSm={isSm}
        leadStatusActivityData={leadStatusActivityData ?? null}
        leadStatuses={leadStatuses}
        isLeadStatusActivityLoading={leadStatusActivityLoading}
        tagsData={tagActivityData?.tags ?? null}
        isTagsDataLoaded={tagActivityLoaded}
        isTagsDataLoading={tagActivityLoading}
        onSelectDate={onSelectDate}
        onExport={handleCreateReport}
        exportIsLoading={reportIsLoading}
        DateFilterUI={DateFilter}
        onSortIconClick={handleSortIconClick}
        sortableColumns={sortableColumns}
      />
      <LeaderboardCustomDateModal
        show={showCustomDateModal}
        onExit={onExitDateModal}
        onSubmit={handleDateModalSubmit}
        filter={dateFilter}
      />
    </div>
  );
});
