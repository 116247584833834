import CampaignContent from './Content'
import { useCallback, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useTypedDispatch } from '../../../../redux/state'
import { updateCampaignTitle } from '../../../../redux/thunks'
import CampaignTitle from '../../../../modals/CampaignTitleModal'
import { CampaignDetailResponse } from '../../../../types/campaign'
import { CampaignCardLoading, Drawer, ModalHeaderToolbar } from '@platform/ui'
import './styles.css'

export interface Props {
  show: boolean
  isLoading: boolean
  campaign?: CampaignDetailResponse
  onExit: () => void
  onViewCampaign: (id: string) => void
}

const CampaignBottomSheet = ({ isLoading, campaign, onViewCampaign, show, onExit }: Props) => {
  const dispatch = useTypedDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const [openRenameCampaignModal, setOpenRenameCampaignModal] = useState(false)
  const [campaignTitle, setCampaignTitle] = useState('')
  const [campaignId, setCampaignId] = useState<string | null>(null)
  const [hasEmptyTitle, setHasEmptyTitle] = useState(true)
  const [isCampaignLoading, setIsCampaignLoading] = useState(false)

  const handleOnRenameTerritory = useCallback((id: string) => {
    setOpenRenameCampaignModal(true)
    setCampaignId(id)
  }, [])

  const handleOnCampaignRenameExit = useCallback(() => {
    setOpenRenameCampaignModal(false)
  }, [])

  const updateTitle = useCallback(async () => {
    if (!campaignId) return
    try {
      setIsCampaignLoading(true)
      await dispatch(updateCampaignTitle(campaignId, campaignTitle, getAccessTokenSilently))
      if (campaign) {
        campaign.title = campaignTitle
      }
    } finally {
      setIsCampaignLoading(false)
      setOpenRenameCampaignModal(false)
    }
  }, [campaignId, campaignTitle, dispatch, getAccessTokenSilently, campaign])

  const handleOnCampaignRenameSubmit = useCallback(() => {
    updateTitle()
  }, [updateTitle])

  const handleOnFormInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const title = e.currentTarget.value
    setCampaignTitle(title)
    setHasEmptyTitle(title.trim() === '')
  }, [])

  return (
    <>
      <Drawer isOpen={show} onClose={handleOnCampaignRenameExit}>
        <ModalHeaderToolbar onBack={onExit} title='Campaign' isDisabled={false} isLoading={isLoading} hasShadow />
        <div className='h-8 w-full bg-white shrink-0'></div>
        {!campaign || isLoading ? (
          <CampaignCardLoading isDesktop={false} shadow={false} />
        ) : (
          <CampaignContent {...campaign} onViewCampaign={onViewCampaign} onRenameTerritory={handleOnRenameTerritory} />
        )}
      </Drawer>
      {openRenameCampaignModal && (
        <CampaignTitle
          onBack={handleOnCampaignRenameExit}
          onSave={handleOnCampaignRenameSubmit}
          onFormInput={handleOnFormInput}
          campaignTitle={campaignTitle}
          isDisabled={hasEmptyTitle}
          isLoading={isCampaignLoading}
        />
      )}
    </>
  )
}

export default CampaignBottomSheet